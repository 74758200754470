

import { useSelector, useDispatch } from "react-redux";
import { useCallback } from "react";
import { geTemporaryPaymentUpdate } from "../store/checkout";

const useInsertTemporarySession = (movieParam,iformacionBan="") => {
    const dispatch = useDispatch();
    const checkoutState = useSelector((state) => state.checkout);
    const homeState = useSelector((state) => state.home);
    const authUser = useSelector((state) => state.auth);

    const insertTemporarySession = useCallback(async () => {
        try {
            const setepPyme = 1;
            const re = await dispatch(
                geTemporaryPaymentUpdate(
                    {
                        peliculaActiva: homeState.activeMovieInfo,
                        informacionPelicula: homeState.allMoviesBillboard,
                        extraInformacion: homeState.arrayInfoMovie,
                    },
                    {
                        peliculaActiva: checkoutState.activeTanda,
                        precioDelista: checkoutState.step2,
                        precioDelistaArray: checkoutState.step2.priceList,
                        asientosSeleccionados: checkoutState.step3,
                        articuloSeleccionado: checkoutState.step4.articules,
                        timeToken: checkoutState.timeToken,
                        tokenSessions: checkoutState.tokenSessions,
                        payment: checkoutState.payment,
                    },
                    {
                        datosUsuario: authUser,
                    },
                    {
                        iformacionBanco: iformacionBan,
                    },
                    setepPyme,
                    movieParam,
                ),
            );
           // console.log("insertartSessionTemporal", insertartSessionTemporal);
        } catch (error) {
          //  console.error("Error inserting temporary session:", error);
        }
    }, [dispatch, homeState, checkoutState, authUser, movieParam]);

    return insertTemporarySession;
};

export default useInsertTemporarySession;
