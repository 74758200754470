import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Backdrop, CircularProgress, useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import sillaNodisponible from "/content/img/SC/sillaNodisponible2.svg";
import asientoseleccionadoRegular from "/content/img/SC/asientoseleccionadoRegular2.svg";
import asiento from "/content/img/SC/asiento.svg";
import asientoley7600 from "/content/img/SC/asientoley7600.svg";
import asientoley7600Disponible from "/content/img/SC/seating/asientoleydisponible.svg";
import companySelected from "../../../../public/content/img/SC/seating/companyselected.svg";
import asientoleycompanyDisponible from "/content/img/SC/seating/asientocopmanydisponible.svg";
import ADasientoseleccionado from "/content/img/SC/ADasientoseleccionado.svg";
import { Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { removeSeatFromSeating, updateStep3Seating, backStep3Seating, updateStep3Complete } from "../../../store/checkout/checkoutSlice";
import { ValidateSeat, addSeatingFunction, deselectSeat, selectSeat } from "../../../store/checkout";
import useInsertTemporarySession from "../../../hooks/updatePymentClient";

// Define the styles for the seats
const Seat = styled("div")(({ theme, selected, blocked, butacanombre, salaid }) => {
    let isSpecialSeat , companySeat;
    if(salaid === 1){    
        isSpecialSeat = butacanombre === "A-D1";
        companySeat = butacanombre === "A-17";
    }
    if(salaid === 2){    
        isSpecialSeat = butacanombre === "A-D1";
        companySeat = butacanombre === "A-3";
    }
    if(salaid === 4){    
        isSpecialSeat = butacanombre === "A-D1";
        companySeat = butacanombre === "A-15";
    }
    if(salaid === 3){    
        isSpecialSeat = butacanombre === "A-D1";
        companySeat = butacanombre === "A-6";
    }
    const themeR = useTheme();
    const isMobile = useMediaQuery(themeR.breakpoints.down("sm"));
    const backgroundImage = blocked === "true" ? `url(${sillaNodisponible})` : selected ? (isSpecialSeat ? `url(${ADasientoseleccionado})`: companySeat ? `url(${companySelected})`: `url(${asientoseleccionadoRegular})`) : isSpecialSeat ? `url(${asientoley7600Disponible})` : companySeat ? `url(${asientoleycompanyDisponible})` :`url(${asiento})`;

    return {
        width: isMobile ? 19 : 35,
        height: isMobile ? 19 : 35,
        margin: isMobile ? "2px" : "8%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: backgroundImage,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        cursor: "pointer",
    };
});

// Define the styles for the seat labels
const LabelButaca = styled(Typography)(({ theme, selected, blocked, butacanombre, salaid }) => {
    let isSpecialSeat , companySeat;
    if(salaid === 1){    
        isSpecialSeat = butacanombre === "A-D1";
        companySeat = butacanombre === "A-17";
    }
    if(salaid === 2){    
        isSpecialSeat = butacanombre === "A-D1";
        companySeat = butacanombre === "A-3";
    }
    if(salaid === 4){    
        isSpecialSeat = butacanombre === "A-D1";
        companySeat = butacanombre === "A-15";
    }
    if(salaid === 3){    
        isSpecialSeat = butacanombre === "A-D1";
        companySeat = butacanombre === "A-6";
    }
    const color = blocked === "true" ? "#000" : selected ? "#000" : "#fff";
    const display = isSpecialSeat ? "none" : companySeat ? "none" : "block";
    const themeR = useTheme();
    const isMobile = useMediaQuery(themeR.breakpoints.down("sm"));

    return {
        fontFamily: "FuturaP-Bold",
        color: color,
        fontSize: isMobile ? 8 : 15,
        display: display,
    };
});

const SeatingChart = ({ movieParam }) => {


    const insertTemporarySession = useInsertTemporarySession(movieParam);
    const { step2, step3, addSeating } = useSelector((state) => state.checkout);
    const dispatch = useDispatch();
    const quantityPeopleStep2 = step2.quantityPeople;
    const themeR = useTheme();
    const isMobile = useMediaQuery(themeR.breakpoints.down("sm"));
    const isTablet = useMediaQuery("(max-width:1024px)"); // Verifica si la pantalla es móvil
    const isSurface = useMediaQuery("(max-width:1370px)"); // Verifica si la pantalla es móvil

    const [seats, setSeats] = useState([]);
    const [messageError, setmessageError] = useState("");
    const [messageSuccess, setmessageSuccess] = useState("");
    const { activeTanda } = useSelector((state) => state.checkout);
    const roomId = activeTanda.room_id_shifts_bp;
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (seats.length > 0) {
            setLoading(false);
        }
    }, [seats]);

    const [loadCount, setLoadCount] = useState(0);

    useEffect(() => {
        if (loadCount < 2) {
            dispatch(addSeatingFunction());
            setLoadCount((prevCount) => prevCount + 1); // Incrementa el contador de ejecuciones
        }
    }, [loadCount]); // Asegúrate de agregar loadCount como dependencia

    useEffect(() => {
        // Actualiza los asientos locales cuando addSeating.seatsData cambie
        const initialSeats = addSeating.seatsData.map((seat) => {
            const isSelected = step3.seating.some((selectedSeat) => selectedSeat.idButaca === seat.butaca);
            return { ...seat, selected: isSelected };
        });
        setSeats(initialSeats);
    }, [addSeating.seatsData, step3.seating]);

    const handleSeatClick = async (butacaId, estado) => {
        // Solo procesar si el estado es 0 (disponible)
        if (estado !== 0) {
            return;
        }
        const divId = `div-seat-${butacaId}`;
        const loadingDiv = document.getElementById(divId);
        if (loadingDiv) {
            loadingDiv.style.visibility = "visible";
        }

        let datanEW = null; // Datos del asiento a procesar
        let isSelected = true; // Indica si el asiento está seleccionado
        let repitSeat = false; // Indica si el asiento ya está seleccionado
        const ValidatedId = butacaId; // Id del asiento a validar
        const selectedSeatsCount = seats.filter((seat) => seat.selected).length; // Contar asientos seleccionados

        const updatedSeats = seats.map((seat) => {
            if (seat.butaca === butacaId && seat.estado === 0) {
                datanEW = { idButaca: butacaId, nombreButaca: seat.ButacaNombre }; // Datos del asiento
                if (!seat.selected && selectedSeatsCount >= quantityPeopleStep2) {
                    setmessageError(`La cantidad de asientos que se deben seleccionar es:  ${quantityPeopleStep2}`);

                    dispatch(removeSeatFromSeating(datanEW));
                    dispatch(backStep3Seating([]));
                    dispatch(addSeatingFunction());
                    insertTemporarySession();
                    repitSeat = true;
                    return seat; // No seleccionar más asientos
                }

                if (seat.selected) {
                    dispatch(removeSeatFromSeating(datanEW)); // Remover asiento seleccionado
                    dispatch(backStep3Seating([]));
                    isSelected = false;
                } else {
                    dispatch(updateStep3Seating([datanEW])); // Agregar asiento seleccionado
                }

                return {
                    ...seat,
                    selected: !seat.selected, // Cambiar estado de selección
                };
            }
            return seat;
        });

        setSeats(updatedSeats); // Actualizar estado de asientos

        if (!repitSeat) {
            if (isSelected) {
                const result = await dispatch(selectSeat(datanEW.idButaca));
                if (result === 200) {
                     insertTemporarySession();
                     setmessageSuccess(`El asiento ${datanEW.nombreButaca} está disponible.`);
                } else {
                    setmessageError(`Este asiento ya está ocupado: ${datanEW.nombreButaca}`);
                    dispatch(removeSeatFromSeating(datanEW));
                    insertTemporarySession();
                }
            } else {
                dispatch(removeSeatFromSeating(datanEW));
                await dispatch(deselectSeat(datanEW.idButaca));
                insertTemporarySession();
            }
            dispatch(backStep3Seating([]));
            dispatch(addSeatingFunction());
            insertTemporarySession();
        }

        if (loadingDiv) {
            loadingDiv.style.visibility = "hidden";
        }
        

        setTimeout(() => {
            setmessageError("");
            setmessageSuccess("");
        }, 3000);
    };

    const rowLabels = Array.from(new Set(addSeating.seatsData.map((seat) => (seat.ButacaNombre ? seat.ButacaNombre.charAt(0) : "")))).sort();

    return (
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={4} style={{ width: "100%", overflowX: isMobile || isTablet || isSurface ? "auto" : "hidden", height: isMobile ? "400px" : "auto" }}>
            <Stack direction="column" justifyContent="flex-end" alignItems="flex-end" spacing={4} style={{ display: isMobile || isTablet || isSurface ? "block" : "none" }}>
                <img src="/content/img/SC/pantalla.png" style={{ marginTop: isMobile ? "0%" : isTablet ? "0%" : isSurface ? "-11%" : "-28%", width: isMobile ? "110%" : isTablet ? "100%" : isSurface ? "80%" : "50%", marginLeft: isMobile ? "15%" : isTablet ? "45%" : isSurface ? "5%" : "0%" }} />
                <Typography style={{ color: "#fff", fontFamily: "FuturaP-ExtraBold", fontSize: isMobile ? "16px" : "32px", marginLeft: isMobile ? "60%" : isTablet ? "85%" : isSurface ? "40%" : "0%", marginTop: isMobile || isTablet || isSurface ? "-15%" : "-28%", marginBottom: "2%" }}>PANTALLA</Typography>
            </Stack>
            <Stack style={{ marginTop: "8%", marginBottom: "6%" }}>
                {messageError && <Typography style={{ fontSize: isMobile ? "10px" : "10", color: "#FF5733", fontFamily: "FuturaP-Bold", marginTop: isMobile ? "0%" : "-2%", marginBottom: isMobile ? "15%" : "10%", marginLeft: isMobile ? "35%" : "0%", textTransform: "uppercase" }}>{messageError}</Typography>}
                {messageSuccess && <Typography style={{ fontSize: isMobile ? "10px" : "10", color: "#67BF47", fontFamily: "FuturaP-Bold", marginTop: isMobile ? "0%" : "-2%", marginBottom: isMobile ? "15%" : "10%", marginLeft: isMobile ? "35%" : "0%", textTransform: "uppercase" }}>{messageSuccess}</Typography>}
            </Stack>

            <Box sx={{ position: "relative", width: isMobile || isTablet ? "100px" : 900, height: isMobile || isTablet ? "300px" : 600, marginTop: isMobile || isTablet ? "5%" : "-2%", display: "flex", justifyContent: "center" }}>
                <Box sx={{ position: "relative", width: isMobile || isTablet ? "50px" : 900, height: isMobile || isTablet ? "auto" : 600, marginLeft: isMobile || isTablet ? "-200%" : "0%", marginTop: isMobile || isTablet ? (roomId === 1 ? "-100%" : "-80%") : roomId === 1 ? "-15%" : "-10%" }}>
                    {rowLabels.map((label, index) => (
                        <Typography
                            key={label + index}
                            sx={{
                                position: "absolute",
                                left: isMobile || isTablet ? -20 : 0,
                                top: index * (isMobile ? 25 : 50) + 5,
                                color: "#fff",
                                fontWeight: "bold",
                                fontSize: isMobile ? "8px" : "inherit",
                                marginTop: isMobile ? (roomId === 1 ? "50%" : "0%") : roomId === 1 ? "6%" : "0%",
                            }}
                        >
                            {label}
                        </Typography>
                    ))}
                    <Backdrop open={loading} style={{ zIndex: 9999 }}>
                        <CircularProgress color="success" />
                    </Backdrop>
                    {!loading &&
                        seats.map((seat, index) => {
                            const seatNumber = seat.ButacaNombre ? seat.ButacaNombre.replace(/^[A-Za-z]-/, "") : "";
                            const divId = `div-seat-${seat.butaca}`;

                            return (
                                <Box
                                    key={(seat.butaca, index)}
                                    sx={{
                                        position: "absolute",
                                        left: isMobile ? seat.ButacaPosX * 0.5 : seat.ButacaPosX + 25,
                                        top: isMobile ? seat.ButacaPosY * 0.5 : seat.ButacaPosY,
                                        textAlign: "center",
                                        cursor: seat.estado === 0 ? "pointer" : "not-allowed",
                                    }}
                                    onClick={() => handleSeatClick(seat.butaca, seat.estado)}
                                >
                                    <LabelButaca variant="caption" display="block" selected={seat.selected} blocked={seat.estado === 1 ? "true" : "false"} butacanombre={seat.ButacaNombre} salaid={seat.salaid} style={{ marginBottom: isMobile ? "-15px" : "-27px" }}>
                                        {seatNumber}
                                    </LabelButaca>

                                    <Seat id={`seatId-${seat.butaca}`} selected={seat.selected} butacanombre={seat.ButacaNombre} salaid={seat.salaid} blocked={seat.estado === 1 ? "true" : "false"}>
                                        <div id={divId} style={{ marginTop: "-14px", visibility: "hidden" }}>
                                            <img src="https://media.tenor.com/t5DMW5PI8mgAAAAj/loading-green-loading.gif" style={{ width: "20px", height: "auto" }} alt="Loading Animation" />
                                        </div>
                                    </Seat>
                                </Box>
                            );
                        })}
                </Box>
            </Box>
        </Stack>
    );
};

export default SeatingChart;
