// Importar componentes y hooks de Material-UI y React
import { Button, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import { updatePriceList, updateStep2Complete } from "../../../store/checkout/checkoutSlice";
import { convertTime } from "../../../hooks/useTimeBillboard";
import { FeeServiceAmount } from "../../../api";
// Definir el componente StyledTableCell con estilos personalizados
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "transparent",
        color: "#fff",
        fontFamily: "FuturaP-Bold",
        textTransform: "uppercase",
        fontSize: 32,
        borderBottom: "none",
        "@media only screen and (max-width: 600px)": {
            backgroundColor: "transparent",
            color: "#fff",
            fontFamily: "FuturaP-Bold",
            textTransform: "uppercase",
            fontSize: 15,
            borderBottom: "none",
        },
    },
    [`&.${tableCellClasses.body}`]: {
        backgroundColor: "transparent",
        color: "#fff",
        fontFamily: "FuturaP-Bold",
        textTransform: "uppercase",
        fontSize: 32,
        borderBottom: "none",
        "@media only screen and (max-width: 600px)": {
            backgroundColor: "transparent",
            color: "rgba(255, 255, 255, 0.70)",
            fontFamily: "FuturaP-Bold",
            textTransform: "uppercase",
            fontSize: 15,
            borderBottom: "none",
        },
    },
}));

// Definir el componente StyledTableRow con estilos personalizados
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

// Función para crear filas de datos en la tabla
function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

// Definir datos de ejemplo para las filas de la tabla
const rows = [createData("Frozen yoghurt", 159, 6.0, 24, 4.0), createData("Ice cream sandwich", 237, 9.0, 37, 4.3), createData("Eclair", 262, 16.0, 24, 6.0), createData("Cupcake", 305, 3.7, 67, 4.3), createData("Gingerbread", 356, 16.0, 49, 3.9)];

// Definir el objeto con la información de la película seleccionada


// Componente principal Step2
const Step2 = () => {
    // Seleccionar el estado step2 desde Redux
    const { activeTanda, step2, message } = useSelector((state) => state.checkout);
    const dispatch = useDispatch(); // Hook para despachar acciones de Redux

    const ariaLabel = { "aria-label": "description" };
    const theme = useTheme(); // Hook para obtener el tema actual
    const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Determina si el dispositivo es móvil según el tamaño de la pantalla
    const isTablet = useMediaQuery("(max-width:1024px)"); // Verifica si la pantalla es móvil
    const isSurface = useMediaQuery("(max-width:1370px)"); // Verifica si la pantalla es móvil

    const { allMoviesBillboard } = useSelector((state) => state.home);


    // Maneja el cambio de cantidad de entradas
    const handleQuantityChange = (id, increment) => {
        const index = step2.priceList.findIndex((item) => item.id === id);

        if (index !== -1) {
            let newQuantity = step2.priceList[index].quantity + increment;
            if (newQuantity < 0) {
                newQuantity = 0; // No permitir cantidad negativa
            }
            const newSubtotal = parseFloat(step2.priceList[index].price) * newQuantity;
            // Actualizar el estado utilizando Redux
            dispatch(updatePriceList({ id, quantity: newQuantity, subtotal: newSubtotal }));
            dispatch(updateStep2Complete(true)); // Actualizar step2.complete a true
        }
    };



    // Encontrar la película correspondiente en allMoviesBillboard usando idMasterMovie y activeTanda.idMaster
    const imageMaster = allMoviesBillboard.find((movie) => movie.idMasterMovie === activeTanda?.idMaster);

    const messageText = typeof message === 'object' ? JSON.stringify(message) : message;

    return (
        <Stack direction={isMobile || isTablet? "column" : "row"} justifyContent="space-evenly" alignItems="center" style={{ marginTop: isMobile ? "25%" : "2%" }}>
            <Stack direction={isMobile || isTablet? "row" : "column"} spacing={isMobile ? 2 : 1} alignItems={isMobile || isTablet? "center" : "flex-start"}>
                <img src={imageMaster?.image} alt="imagen" width={isMobile || isTablet? "120px" : "313px"} height={isMobile || isTablet? "155px" : "441px"} />
                <Stack>
                    <Stack direction="row" spacing={1}>
                        <Typography style={{ color: "white", fontFamily: "FuturaP-Bold", fontSize: isMobile ? "12px" : isSurface ? "15px" : "20px", textTransform: "uppercase" }}>Pelicula:</Typography>
                        <Typography style={{ color: "white", fontFamily: "FuturaP-Light", fontSize: isMobile ? "12px" : isSurface ? "15px" : "20px", fontWeight: "100", textTransform: "uppercase" }}>{activeTanda.name_movie_bp}</Typography>
                    </Stack>

                    <Stack direction="row" spacing={1}>
                        <Typography style={{ color: "white", fontFamily: "FuturaP-Bold", fontSize: isMobile ? "12px" : isSurface ? "15px" : "20px", textTransform: "uppercase" }}>DÍA:</Typography>
                        <Typography style={{ color: "white", fontFamily: "FuturaP-Light", fontSize: isMobile ? "12px" : isSurface ? "15px" : "20px", fontWeight: "100", textTransform: "uppercase" }}>{convertTime(activeTanda.date_time_shifts_bp)}</Typography>
                    </Stack>

                    <Stack direction="row" spacing={1}>
                        <Typography style={{ color: "white", fontFamily: "FuturaP-Bold", fontSize: isMobile ? "12px" : isSurface ? "15px" : "20px", textTransform: "uppercase" }}>SALA:</Typography>
                        <Typography style={{ color: "white", fontFamily: "FuturaP-Light", fontSize: isMobile ? "12px" : isSurface ? "15px" : "20px", fontWeight: "100", textTransform: "uppercase" }}>{activeTanda.room_name_shifts_bp}</Typography>
                    </Stack>

                    <Stack direction="row" spacing={1}>
                        <Typography style={{ color: "white", fontFamily: "FuturaP-Bold", fontSize: isMobile ? "12px" : isSurface ? "15px" : "20px", textTransform: "uppercase" }}>HORA:</Typography>
                        <Typography style={{ color: "white", fontFamily: "FuturaP-Light", fontSize: isMobile ? "12px" : isSurface ? "15px" : "20px", fontWeight: "100", textTransform: "uppercase" }}>{activeTanda.hora}</Typography>
                    </Stack>
                </Stack>
            </Stack>

            <Stack spacing={isMobile || isTablet? 2 : 5} direction="column" justifyContent="flex-start" alignItems={isMobile || isTablet? "center" : "flex-start"}>
                <Stack direction="row" spacing={2} style={{ marginTop: isMobile || isTablet? "8%" : "0%" }}>
                    <Typography
                        style={{
                            color: "white",
                            fontFamily: "FuturaP-ExtraBold",
                            fontSize: isMobile ? "15px" : "32px",
                            textTransform: "uppercase",
                            backgroundColor: "#66B02E",
                            paddingTop: isMobile ? "3px" : "3px",
                            paddingLeft: isMobile ? "8px" : "15px",
                            paddingRight: isMobile ? "8px" : "14px",
                            paddingBottom: isMobile ? "1px" : "1px",
                            borderRadius: "100%",
                            display: isMobile || isTablet? "block" : "none",
                        }}
                    >
                        2
                    </Typography>
                    <Typography
                        style={{
                            color: "white",
                            fontFamily: "FuturaP-ExtraBold",
                            fontSize: isMobile ? "15px" : "32px",
                            fontWeight: "100",
                            textTransform: "uppercase",
                        }}
                    >
                        ENTRADAS
                    </Typography>
                </Stack>

                <TableContainer style={{ display: messageText ? "none" : "block" }}>
                    <Table sx={{ minWidth: isMobile || isTablet? 360 : 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Tipo</StyledTableCell>
                                <StyledTableCell align="center" style={{ display: isMobile || isTablet? "none" : "block" }}>
                                    precio
                                </StyledTableCell>
                                <StyledTableCell align="center">cantidad</StyledTableCell>
                                <StyledTableCell align="center">subtotal</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {step2.priceList.map((item, index) => (
                                <StyledTableRow key={item.name + index + item.price}>
                                    <StyledTableCell>
                                        {item.name} {isMobile || isTablet? <br /> : null}
                                        {isMobile || isTablet? `¢ ${new Intl.NumberFormat("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(item.price)}` : ""}
                                    </StyledTableCell>
                                    <StyledTableCell align="center" style={{ display: isMobile || isTablet? "none" : "block" }}>
                                        ¢ {new Intl.NumberFormat("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(item.price)}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <Stack
                                            direction="row"
                                            justifyContent="space-evenly"
                                            alignItems="center"
                                            style={{
                                                width: isMobile ? "135px" : "100%",
                                                height: isMobile ? "54px" : "43px",
                                                backgroundColor: "rgba(41, 200, 185, 0.30)",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <Button className="btnEntradas" value="menos" onClick={() => handleQuantityChange(item.id, -1)} disabled={item.quantity <= 0}>
                                                -
                                            </Button>
                                            <Typography
                                                style={{
                                                    color: isMobile ? "rgba(255, 255, 255, 0.70)" : "#fff",
                                                    fontFamily: "FuturaP-Bold",
                                                    textTransform: "uppercase",
                                                    fontSize: "24px",
                                                }}
                                            >
                                                {item.quantity}
                                            </Typography>
                                            <Button className="btnEntradas" value="mas" onClick={() => handleQuantityChange(item.id, 1)}>
                                                +
                                            </Button>
                                        </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">¢ {new Intl.NumberFormat("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format([item.subtotal])}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                {message ? (
                    <Stack justifyContent="center" alignItems="center" spacing={2} style={{ borderRadius: "20px", border: " 1px solid rgba(255, 255, 255, 0.20)", backgroundColor: "rgba(0, 0, 0, 0.24)", width: isMobile ? "90%" : "75%", paddingTop: "5%", paddingBottom: "5%" }}>
                        <img src="/content/img/SC/nohaypelicula.svg" width={"30%"} />
                        <Typography style={{ fontFamily: "FuturaP-ExtraBold", color: "#fff", textTransform: "uppercase", fontSize: isMobile ? "30px" : "48px", marginBottom: "0%" }}>opps!</Typography>
                        <Typography style={{ fontFamily: "FuturaP-Medium", color: "#fff", textTransform: "uppercase", fontSize: isMobile ? "10px" : "15px", textAlign: "center", marginTop: "-2%" }}>
                            <br />
                            {messageText}
                        </Typography>
                        <Typography style={{ fontFamily: "FuturaP-Medium", color: "#fff", textTransform: "uppercase", fontSize: isMobile ? "10px" : "15px" }}>¡cONSULTA MÁS TARDE!</Typography>
                    </Stack>
                ) : (
                    <Typography
                        style={{
                            color: "#fff",
                            fontFamily: "FuturaP-Light",
                            textTransform: "uppercase",
                            fontSize: isMobile ? "13px" : "20px",
                            fontWeight: "100",
                            width: isMobile ? "95%" : "80%",
                        }}
                    >
                        TODAS LAS ENTRADAS ESTÁN SUJETAS AL CARGO DE EMISIÓN DE BOLETO DIGITAL POR ¢{FeeServiceAmount} POR ENTRADA
                    </Typography>
                )}
            </Stack>
        </Stack>
    );
};

export default Step2;
