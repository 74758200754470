import { FetchPriceListAsync, InsertArituculeAsync, addSeatingFunctionAsync, create_Invoice_tem_Cineverse, delete_Temporal_InvoiceAsync, delete_Temporal_transactionAsync, geTemporaryPaymentFunctionAsync, geTemporaryPaymentInsertFunctionAsync, geTemporaryPaymentUpdateFunctionAsync, getArituculeAsync, getInvoiceIdAsync, insertCHiledLine, insertFatherLine, insertPaymentAttemptAsync, insertSessionIdAsync, insertWebClientAsync, processArticulosPaymentAsync, sessionActiveAsync, trasnsactionPaymentAsync, updateClientTransactionAsync, updateSessionStatusAsync } from "./Api_checkout_Provider";
import { InsertArticule, cleararticule, getaddSeating, insertFactura, insertPriceList, insertmessage } from "./checkoutSlice";
import { FeeServiceAmount, errorMessages, secretKey } from "../../api";
import { isPageError210, isPageError500, seterrorMessage } from "../home";
import { deselectSeatAsync, selectSeatAsync } from "../home/Api_Movies_Provider";
import CryptoJS from "crypto-js";
import { errorMessage } from "../pymentStoree/pymentStoree";

export const addSeatingFunction = () => {
    return async (dispatch, getState) => {
        try {
            dispatch(insertmessage(""));
            /* Acciones a ejecutar dependiendo del código de estado de la respuesta */
            const statusActions = {
                500: () => dispatch(seterrorMessage({ errorMessage: errorMessages[0] })),
                210: () => dispatch(seterrorMessage({ errorMessage: errorMessages[1] })),
            };
            const { activeTanda, tokenSessions } = getState().checkout;

            const { room_id_shifts_bp, scheme_id_shifts_bp, scheme_Detail_Id_shifts_bp, date_time_shifts_bp } = activeTanda;

            const result = await addSeatingFunctionAsync({ sala_id: room_id_shifts_bp, esquema_id: scheme_id_shifts_bp, esquema_detalle_id: scheme_Detail_Id_shifts_bp, fecha: date_time_shifts_bp, idSesion: tokenSessions });
            /* proceso de validacion de la respuesta de la API y errores */
            if (result.ok === false) {
                dispatch(isPageError500(false));
                return statusActions[500]();
            }

            // Se obtiene el código de estado y los datos de la respuesta
            const { statusCode, data } = result.data;

            if (result.data.error) {
                dispatch(isPageError210(false));
                dispatch(insertmessage("Lo sentimos, no se pueden mostrar los precios de esta película. Por favor, actualice la página e intente nuevamente."));
                return;
            }
            if (statusActions[statusCode]) {
                dispatch(insertmessage("Lo sentimos, no se pueden mostrar los precios de esta película. Por favor, actualice la página e intente nuevamente."));
                statusActions[statusCode]();
                return;
            }

            const seatsData = data.map((item) => ({
                salaid: item.Sala_id,
                butaca: item.Butaca_id,
                ButacaNombre: item.Butaca_Nombre,
                ButacaPosX: item.Butaca_PosX,
                ButacaPosY: item.Butaca_PosY,
                estado: item.estado,
            }));

            const seatPositions = {
                1: {
                    216: { x: 370, y: 550 },
                    217: { x: 405, y: 550 },
                    25: { x: 600, y: 47 },
                    26: { x: 635, y: 47 },
                    // Añade más butacas y posiciones aquí
                },
                2: {
                    10: { x: 376, y: 0 },
                    9: { x: 336, y: 0 },
                    8: { x: 298, y: 0 },
                    7: { x: 261, y: 0 },
                    3: { x: 224, y: -3 },
                    4: { x: 187, y: -3 },
                    // Añade más butacas y posiciones aquí
                },
                4: {
                    22: { x: 295, y: 50 },
                    21: { x: 255, y: 50 },
                    20: { x: 215, y: 50 },
                    19: { x: 172, y: 50 },
                    23: { x: 332, y: 50 },
                    24: { x: 375, y: 50 },
                    25: { x: 415, y: 50 },
                    26: { x: 455, y: 50 },
                    27: { x: 495, y: 50 },
                    28: { x: 535, y: 50 },
                    29: { x: 575, y: 50 },
                    30: { x: 615, y: 50 },
                    31: { x: 655, y: 50 },
                    72: { x: 455, y: 200 },
                    133: { x: 295, y: 400 },
                    132: { x: 255, y: 400 },
                    131: { x: 215, y: 400 },
                    130: { x: 172, y: 400 },
                    134: { x: 332, y: 400 },
                    135: { x: 375, y: 400 },
                    136: { x: 415, y: 400 },
                    137: { x: 455, y: 400 },
                    138: { x: 495, y: 400 },
                    139: { x: 535, y: 400 },
                    140: { x: 575, y: 400 },
                    141: { x: 615, y: 400 },
                    142: { x: 655, y: 400 },
                    144: { x: 255, y: 450 },
                    143: { x: 215, y: 450 },
                    145: { x: 295, y: 450 },
                    146: { x: 332, y: 450 },
                    147: { x: 375, y: 450 },
                    148: { x: 415, y: 450 },
                    149: { x: 455, y: 450 },
                    150: { x: 495, y: 450 },
                    151: { x: 535, y: 450 },
                    152: { x: 575, y: 450 },
                    153: { x: 615, y: 450 },
                    154: { x: 655, y: 450 },
                    13: { x: 655, y: -3 },
                    14: { x: 615, y: -3 },
                    // Añade más butacas y posiciones aquí
                },
            };

            seatsData.forEach((item) => {
                const position = seatPositions[item.salaid]?.[item.butaca];
                if (position) {
                    item.ButacaPosX = position.x;
                    item.ButacaPosY = position.y;
                }
            });

            // Ordenar seatsData alfabéticamente por ButacaNombre
            const sortedSeatsData = seatsData.sort((a, b) => {
                if (a.ButacaNombre < b.ButacaNombre) return -1;
                if (a.ButacaNombre > b.ButacaNombre) return 1;
                return 0;
            });
            const { seating } = getState().checkout.step3;

            const resulButaca = await sessionActiveAsync({ esquema_id: scheme_id_shifts_bp, esquema_detalle_id: scheme_Detail_Id_shifts_bp, sessionId: tokenSessions, fecha_pelicula: date_time_shifts_bp });

            if (resulButaca && resulButaca.data.data.length > 0) {
                resulButaca.data.data.forEach((resulItem) => {
                    if (resulItem.Id_Sesion === tokenSessions) {
                        seating.forEach((seat) => {
                            if (seat.idButaca === resulItem.Butaca_id) {
                                sortedSeatsData.forEach((sortedItem) => {
                                    if (sortedItem.butaca === resulItem.Butaca_id) {
                                        sortedItem.estado = 0;
                                    }
                                });
                            }
                        });
                    }
                });
            }

            // Despacho de la acción con los datos ordenados
            dispatch(getaddSeating(sortedSeatsData));
            // Aquí puedes manejar el resultado de la petición
        } catch (error) {
            console.error("Precio de lista", error);
        }
    };
};

export const insertSessionId = (newIdSession) => {
    // Función para obtener la fecha actual en formato YYYY-MM-DD
    const getCurrentDateFormatted = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Los meses van de 0 a 11
        const day = String(currentDate.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    };

    // Función para obtener la hora actual en formato HH:MM:SS
    const getCurrentTimeFormatted = () => {
        const currentDate = new Date();
        const hours = String(currentDate.getHours()).padStart(2, "0");
        const minutes = String(currentDate.getMinutes()).padStart(2, "0");
        const seconds = String(currentDate.getSeconds()).padStart(2, "0");
        return `${hours}:${minutes}:00`;
    };

    /* Esta función es un thunk que se encarga de hacer la petición al servidor para obtener la lista de Peliculas */
    return async (dispatch, getState) => {
        try {
            // Obtiene la fecha y hora actual en los formatos deseados
            const currentDate = getCurrentDateFormatted();
            const currentTime = getCurrentTimeFormatted();
            const flag_sessions_cv = "flag_de_la_sesion";
            const seats_sessions_cv = "asientos_de_la_sesion";

            const { activeTanda } = getState().checkout;
            const { room_id_shifts_bp, scheme_id_shifts_bp, scheme_Detail_Id_shifts_bp, date_time_shifts_bp, start_time_shifts_bp } = activeTanda;

            insertSessionIdAsync({
                id_sessions_cv: newIdSession,
                date_sessions_cv: currentDate,
                time_sessions_cv: currentTime,
                flag_sessions_cv,
                seats_sessions_cv,
                movie_date_sessions_cv: `${date_time_shifts_bp}  ${start_time_shifts_bp}`, //2024-07-02 15:10:00 fecha de pelicual y hora exacta
                room_sessions_cv: room_id_shifts_bp,
                esquemaid_sessions_cv: scheme_id_shifts_bp,
                esquema_detalleid_sessions_cv: scheme_Detail_Id_shifts_bp,
            });
        } catch (error) {
            console.error("Error generating session ID or getting current date/time:", error);
        }
    };
};

export const FetchPriceList = () => {
    /* Esta función es un thunk que se encarga de hacer la petición al servidor para obtener la lista de Peliculas */
    return async (dispatch, getState) => {
        try {
            dispatch(insertmessage(""));

            /* Acciones a ejecutar dependiendo del código de estado de la respuesta */
            const statusActions = {
                500: () => dispatch(seterrorMessage({ errorMessage: errorMessages[0] })),
                210: () => dispatch(seterrorMessage({ errorMessage: errorMessages[1] })),
            };
            const { activeTanda } = getState().checkout;
    
            const { id_movie_shifts_bp, date_time_shifts_bp, start_time_shifts_bp } = activeTanda;

            const result = await FetchPriceListAsync({ pelicula_id: id_movie_shifts_bp, fechaTanda: date_time_shifts_bp, horaEnvio: start_time_shifts_bp });

            /* proceso de validacion de la respuesta de la API y errores */
            if (result.ok === false) {
                dispatch(isPageError500(false));
                return statusActions[500]();
            }

            // Se obtiene el código de estado y los datos de la respuesta
            const { statusCode, data } = result.data;

            if (result.data.error) {
                dispatch(isPageError210(false));
                dispatch(insertmessage("Lo sentimos, pero en este momento no se pueden mostrar los precios de esta película"));
                return;
            }
            if (statusActions[statusCode]) {
                dispatch(insertmessage("Lo sentimos, pero en este momento no se pueden mostrar los precios de esta película."));
                statusActions[statusCode]();
                return;
            }

            const formattedArray = data.map((item, index) => ({
                id: index + 1, // Puedes ajustar este ID según sea necesario
                name: item.Cat_Description_pricelist_bp,
                price: parseFloat(item.price_pricelist_bp), // Aseguramos que el precio sea un número
                quantity: 0,
                subtotal: 0,
                // Incluimos todas las propiedades originales
                cat_id_pricelist_bp: item.cat_id_pricelist_bp,
                list_Id_pricelist_bp: item.list_Id_pricelist_bp,
                list_name_pricelist_bp: item.list_name_pricelist_bp,
                shifts_date_pricelist_bp: item.shifts_date_pricelist_bp,
                shifts_start_time_pricelist_bp: item.shifts_start_time_pricelist_bp,
            }));

            dispatch(insertPriceList(formattedArray));

            return formattedArray;

            // Aquí puedes manejar el resultado de la petición
        } catch (error) {
            // console.error("Precio de lista", error);
        }
    };
};

export const ValidateSeat = (butacaId) => {
    return async (dispatch, getState) => {
        try {
            // Llamada a la función addSeatingFunction para actualizar la información de los asientos
            await dispatch(addSeatingFunction());

            // Obtiene el estado actual de los datos de los asientos desde el estado de Redux
            const { seatsData } = getState().checkout.addSeating;

            // Encuentra el asiento correspondiente al ID de la butaca proporcionado
            const seat = seatsData.find((seat) => seat.butaca === butacaId);

            // Verifica el estado de la butaca (0: disponible, 1: ocupado) y retorna el resultado
            const result = seat.estado === 0 ? 0 : 1;

            // Retorna el resultado de la verificación
            return result;

            // Aquí puedes continuar con cualquier lógica adicional para selectedMySeat
        } catch (error) {
            // Manejo de errores en caso de fallo al seleccionar la butaca
            console.error("Error al seleccionar la butaca", error);
        }
    };
};

export const selectSeat = (butacaId) => {
    return async (dispatch, getState) => {
        try {
            dispatch(insertmessage(""));
            /* Acciones a ejecutar dependiendo del código de estado de la respuesta */
            const statusActions = {
                500: () => dispatch(seterrorMessage({ errorMessage: errorMessages[0] })),
                210: () => dispatch(seterrorMessage({ errorMessage: errorMessages[1] })),
            };
            const { activeTanda, tokenSessions, step3 } = getState().checkout;
            const { room_id_shifts_bp, scheme_id_shifts_bp, scheme_Detail_Id_shifts_bp, date_time_shifts_bp, id_movie_bp, name_movie_bp, start_time_shifts_bp } = activeTanda;

            const fechaFormat = `${date_time_shifts_bp} ${start_time_shifts_bp}`;

            const result = await selectSeatAsync({ sala_id: room_id_shifts_bp, butaca_id: butacaId, esquema_id: scheme_id_shifts_bp, esquema_detalle_id: scheme_Detail_Id_shifts_bp, fecha: fechaFormat, pelicula_id: id_movie_bp, pelicula_nombre: name_movie_bp, idSesion: tokenSessions });

            await updateSessionStatusAsync({
                status: 2,
                sessionId: tokenSessions,
            });
            return result.data.statusCode;
        } catch (error) {
            // Manejo de errores en caso de fallo al seleccionar la butaca
            console.error("selectSeat", error);
        }
    };
};

export const deselectSeat = (butacaId) => {
    return async (dispatch, getState) => {
        try {
            dispatch(insertmessage(""));
            /* Acciones a ejecutar dependiendo del código de estado de la respuesta */
            const statusActions = {
                500: () => dispatch(seterrorMessage({ errorMessage: errorMessages[0] })),
                210: () => dispatch(seterrorMessage({ errorMessage: errorMessages[1] })),
            };
            const { activeTanda, tokenSessions, step3 } = getState().checkout;
            const { room_id_shifts_bp, scheme_id_shifts_bp, scheme_Detail_Id_shifts_bp, date_time_shifts_bp, start_time_shifts_bp } = activeTanda;

            const fechaFormat = `${date_time_shifts_bp} ${start_time_shifts_bp}`;

            const result = await deselectSeatAsync({ sala_id: room_id_shifts_bp, butaca_id: butacaId, esquema_id: scheme_id_shifts_bp, esquema_detalle_id: scheme_Detail_Id_shifts_bp, fecha: fechaFormat });

            await updateSessionStatusAsync({
                status: step3.seating.length > 0 ? 2 : 0,
                sessionId: tokenSessions,
            });

            return result.data.statusCode;
        } catch (error) {
            // Manejo de errores en caso de fallo al seleccionar la butaca
            console.error("Error deselectSeat", error);
        }
    };
};

export const getAritucule = () => {
    return async (dispatch, getState) => {
        try {
            const result = await getArituculeAsync();
            dispatch(InsertArticule(result.data));
            return result.data;
        } catch (error) {
            // Manejo de errores en caso de fallo al seleccionar la butaca
            console.error("Error deselectSeat", error);
        }
    };
};
export const InserApiAritucule = (category, productData) => {
    return async (dispatch, getState) => {
        const result = await InsertArituculeAsync({ category, productData });
        await dispatch(getAritucule());
        return result.data;
    };
};

/************************************************************************************************************************************************************************************/
/* generar transaccion de pago para confiteria y butaca ********************************************************/
const clearTemporaryData = async (tokenSessions) => {
    await delete_Temporal_InvoiceAsync({ idSesion: tokenSessions });
    await delete_Temporal_transactionAsync({ idSesion: tokenSessions });
};

// Función para procesar artículos
const processArticlesArticulos = async (articles, tokenSessions) => {
    // console.log("tokenSessions", tokenSessions);
    // Process the primary article data
    const primaryProcessingPromises = articles.map((article, indexOffTem) =>
        article.sp_bp_ecext.map(async (sp) => {
            const indexOff = indexOffTem + 1;
            //("indexOff", sp.SP_nameProduct,  indexOff);
            const commonData = {
                idSesion: tokenSessions,
                product_id: sp.SP_idproduct,
                product_name: sp.SP_nameProduct,
                product_price1: sp.SP_subTotalProduct,
                cantidad: sp.SP_cantidad,
                product_combo: sp.SP_isCombo,
                presentation_type: sp.SP_presentacion,
                line: indexOff,
            };

            if (sp.SP_padre === 1) {
                // Insert father line and handle child choices
                await insertFatherLine(commonData);
                await Promise.all(
                    sp.SP_escogencias.map(async (escogencia) => {
                        const grupo = escogencia.group ?? escogencia.groupDrinks ?? escogencia.groupExtras;
                        await insertCHiledLine({
                            idSesion: tokenSessions,
                            line: indexOff,
                            id_padre: sp.SP_idproduct,
                            name_combo: sp.SP_nameProduct,
                            product_detail: escogencia.id,
                            nombre_escogencia: escogencia.name,
                            grupo,
                        });
                    }),
                );
            } else if (sp.SP_padre === 0) {
                // Insert only the father line
                await insertFatherLine(commonData);
            }
        }),
    );

    // Wait for all primary processing promises to complete
    await Promise.all(primaryProcessingPromises.flat());

    // Process individual items if they are part of a combo
    const individualProcessingPromises = articles.map((article, indexOff) =>
        article.sp_bp_ecext.map(async (sp) => {
            if (sp.SP_padre === 1) {
                await Promise.all(
                    sp.SP_individaules.map(async (individual) => {
                        const sumaLine = sp.SP_number_line + 100 + indexOff;
                        await insertFatherLine({
                            idSesion: tokenSessions,
                            product_id: individual.id,
                            product_name: individual.name,
                            product_price1: individual.price,
                            cantidad: 1,
                            product_combo: 0,
                            presentation_type: 0,
                            line: sumaLine,
                        });
                    }),
                );
            }
        }),
    );

    // Wait for all individual processing promises to complete
    await Promise.all(individualProcessingPromises.flat());
};

const processArticles = async (articles, tokenSessions) => {
    const numero = 2;
    // Process the primary article data
    const primaryProcessingPromises = articles.map((article, indexOff) =>
        article.sp_bp_ecext.map(async (sp) => {
            const numeroLinea = numero + indexOff;
            //console.log("numeroLinea", numeroLinea);
            const commonData = {
                idSesion: tokenSessions,
                product_id: sp.SP_idproduct,
                product_name: sp.SP_nameProduct,
                product_price1: sp.SP_subTotalProduct,
                cantidad: sp.SP_cantidad,
                product_combo: sp.SP_isCombo,
                presentation_type: sp.SP_presentacion,
                line: numeroLinea,
            };

            if (sp.SP_padre === 1) {
                // Insert father line and handle child choices
                await insertFatherLine(commonData);
                await Promise.all(
                    sp.SP_escogencias.map(async (escogencia) => {
                        const grupo = escogencia.group ?? escogencia.groupDrinks ?? escogencia.groupExtras;
                        await insertCHiledLine({
                            idSesion: tokenSessions,
                            line: numeroLinea,
                            id_padre: sp.SP_idproduct,
                            name_combo: sp.SP_nameProduct,
                            product_detail: escogencia.id,
                            nombre_escogencia: escogencia.name,
                            grupo,
                        });
                    }),
                );
            } else if (sp.SP_padre === 0) {
                // Insert only the father line
                await insertFatherLine(commonData);
            }
        }),
    );

    // Wait for all primary processing promises to complete
    await Promise.all(primaryProcessingPromises.flat());

    // Process individual items if they are part of a combo
    const individualProcessingPromises = articles.map((article, indexOff) =>
        article.sp_bp_ecext.map(async (sp) => {
            if (sp.SP_padre === 1) {
                await Promise.all(
                    sp.SP_individaules.map(async (individual) => {
                        const sumaLine = sp.SP_number_line + 100 + indexOff;
                        await insertFatherLine({
                            idSesion: tokenSessions,
                            product_id: individual.id,
                            product_name: individual.name,
                            product_price1: individual.price,
                            cantidad: 1,
                            product_combo: 0,
                            presentation_type: 0,
                            line: sumaLine,
                        });
                    }),
                );
            }
        }),
    );

    // Wait for all individual processing promises to complete
    await Promise.all(individualProcessingPromises.flat());
};

// Función para manejar la transacción principal
const handleTransaction = async (params, createTemp, applyTemp) => {
    return await trasnsactionPaymentAsync({
        ...params,
        crear_temporal: createTemp,
        aplicar_temporal: applyTemp,
    });
};

// Función para manejar la factura firme de confitería y butaca
export const insertartFacturaFirmeConfiteriaButaca = () => {
    return async (dispatch, getState) => {
        try {
            // Primero, extraemos la información necesaria del estado de Redux
            const { tokenSessions, step2, step3, step4, activeTanda, payment } = getState().checkout; // Estado de checkout
            const { id_user, name_user, email_user, status_user, lasname_user, phone_user, indetification_user, birthdate, token_user, mensajeError, mensajeErrorLogin, type_user } = getState().auth; // Estado de autenticación
            const { arrayInfoMovie } = getState().home; // Estado de la página principal (home)

            // Creamos un array con la información del usuario
            const userArray = [id_user, name_user, email_user, status_user, lasname_user, phone_user, indetification_user, birthdate, token_user, mensajeError, mensajeErrorLogin, type_user];

            // Obtenemos el mensaje encriptado almacenado en el localStorage
            const encryptedMessage = localStorage.getItem("pytmi");

            // Array para almacenar la información desencriptada
            const infopytmi = [];
            // Variable para almacenar el ID de la transacción
            let transactionId = 0;

            // Verificamos si hay un mensaje encriptado
            if (encryptedMessage) {
                // Desencriptamos el mensaje usando la clave secreta
                const decryptedMessage = CryptoJS.AES.decrypt(encryptedMessage, secretKey).toString(CryptoJS.enc.Utf8);

                // Parseamos el mensaje desencriptado a un objeto
                const parsedMessage = JSON.parse(decryptedMessage);

                // Extraemos el código de respuesta y otros datos del mensaje desencriptado
                const responseCode = parseInt(parsedMessage.responseCode, 10);
                const messageObj = JSON.parse(decryptedMessage);
                transactionId = messageObj.transactionid; // Asignamos el ID de la transacción

                // Agregamos la información desencriptada al array `infopytmi`
                infopytmi.push({
                    parsedMessage: parsedMessage, // Mensaje parseado
                    responseCode: responseCode, // Código de respuesta
                    messageObj: messageObj, // Objeto del mensaje
                    transactionId: transactionId, // ID de la transacción
                });
            }

            // Asignamos valores predeterminados en caso de que falte alguna información del usuario
            const cedulaUser = indetification_user || "000"; // Cédula del usuario o "000" si no está disponible
            const nameUser = `${name_user} ${lasname_user}`; // Nombre completo del usuario
            const cantseating = step3.seating.length; // Cantidad de asientos seleccionados

            // Definimos los parámetros comunes para la transacción
            const commonParams = {
                idSesion: tokenSessions, // ID de la sesión
                cant: cantseating, // Cantidad de asientos seleccionados
                nombreCliente: nameUser, // Nombre del cliente
                cedulaCliente: cedulaUser, // Cédula del cliente
            };

            // Realizamos la transacción y obtenemos el ID de la factura
            const returnInvoiceId = await handleTransaction(commonParams, 0, 1);
            const invoiceIdBp = returnInvoiceId?.data?.data?.[0]?.Invoice_Id; // Obtenemos el ID de la factura

            // Inicializar estados
            let estadoCompra = "pendiente";
            let bussisnes_pro = "Pendiente bussisnes_pro";
            let banck = "Pending del banco";
            let invoiceTemp = 0;

            // Actualizar estados basado en condiciones
            if (invoiceIdBp || transactionId > 0) {
                estadoCompra = "aceptado";
            }

            if (invoiceIdBp) {
                bussisnes_pro = "aceptado";
                invoiceTemp = invoiceIdBp;
            }

            if (transactionId > 0) {
                banck = "aceptado";
            }

            // Creamos un objeto con los datos de pago pendientes
            const DataPymentPneding = {
                infoCLient: userArray, // Información del cliente
                priceList: step2, // Información de la lista de precios (asientos)
                seating: step3, // Información de los asientos seleccionados
                articules: step4.articules, // Información de los artículos comprados en la confitería
                movieData: activeTanda, // Información de la tanda de la película
                tokenUser: tokenSessions, // Token de la sesión del usuario
                payments: payment, // Información del pago
                transBank: transactionId, // ID de la transacción bancaria
                movieDetail: arrayInfoMovie, // Detalles adicionales de la película
                FeeServiceAmount: FeeServiceAmount,
                bussisnes_pro: bussisnes_pro,
                banck: banck,
                infopytmi,
            };

            // Actualizamos la transacción del cliente con los datos recopilados
            await updateClientTransactionAsync({
                idSession: tokenSessions, // ID de la sesión
                idInvoice: invoiceTemp, // ID de la factura
                clavefe: "0", // Clave de la factura electrónica (0 en este caso)
                status: estadoCompra, // Estado de la transacción (aceptado)
                transBank: transactionId, // ID de la transacción bancaria
                DataPymentPneding: DataPymentPneding, // Datos de pago pendientes
            });

            // Información a enviar al insertar la factura
            const info = {
                invoiceIdBp: invoiceTemp, // ID de la factura
                transactionId: transactionId, // ID de la transacción
                infopytmi, // Información desencriptada del pago
            };
            // Insertamos la factura en la base de datos
            dispatch(insertFactura(info));

            if (invoiceIdBp || transactionId > 0) {
                await updateSessionStatusAsync({ status: 1, sessionId: tokenSessions });
            }
            if (invoiceIdBp) {
                // Devolvemos el ID de la factura y un ID de transacción ficticio (100)
                return { invoiceIdBp, transactionId: transactionId };
            } else{
                return { invoiceIdBp: 0, transactionId: "" };
            }
        } catch (error) {
            // En caso de error, mostramos un mensaje de error en la consola y devolvemos null
            //  console.error("Error en trasnsactionPayment:", error);
            return { invoiceIdBp: "", transactionId: "" };
        }
    };
};

// Función para manejar la factura temporal
export const insertartFacturaTempralConfiteriaButaca = () => {
    return async (dispatch, getState) => {
        try {
            // Obtener datos del estado global (Redux)
            const { tokenSessions, step2, step3, step4, activeTanda, payment } = getState().checkout; // Estado de checkout
            const { id_user, name_user, email_user, status_user, lasname_user, phone_user, indetification_user, birthdate, token_user, mensajeError, mensajeErrorLogin, type_user } = getState().auth; // Estado de autenticación
            const { arrayInfoMovie } = getState().home; // Estado de la página principal (home)

            // Crear un array con la información del usuario
            const userArray = [id_user, name_user, email_user, status_user, lasname_user, phone_user, indetification_user, birthdate, token_user, mensajeError, mensajeErrorLogin, type_user];

            // Limpiar datos temporales en el servidor usando el token de la sesión
            await clearTemporaryData(tokenSessions);

            // Calcular la cantidad total de personas
            const totalquantityPeople = Math.max(step2.quantityPeople, 0);

            // Calcular el costo del servicio
            const FeeService = totalquantityPeople * FeeServiceAmount;

            // Calcular el total del paso 2 (asientos)
            const totalPriceList = totalquantityPeople > 0 ? step2.totalStep2 : 0;

            // Calcular el subtotal de los artículos en el paso 4 (confitería)
            const totalSubTotal = step4.articules.reduce((acc, producto) => acc + producto.subTotal, 0);

            // Calcular el total del pago incluyendo el costo del servicio y el total de asientos
            const totalPayment = totalSubTotal + FeeService + totalPriceList;

            // Crear un objeto con los datos de pago pendientes
            const DataPymentPneding = {
                infoCLient: userArray, // Información del cliente
                priceList: step2, // Información de la lista de precios (asientos)
                seating: step3, // Información de los asientos seleccionados
                articules: step4.articules, // Información de los artículos comprados en la confitería
                movieData: activeTanda, // Información de la tanda de la película
                tokenUser: tokenSessions, // Token de la sesión del usuario
                payments: payment, // Información del pago
                transBank: "", // Información de la transacción bancaria (vacío en este caso)
                movieDetail: arrayInfoMovie, // Detalles adicionales de la película
                FeeServiceAmount: FeeServiceAmount,
            };

            // Insertar un intento de pago con los datos recopilados
            await insertPaymentAttemptAsync({
                idSession: tokenSessions, // ID de la sesión
                idInvoice: "", // ID de la factura (vacío en este caso)
                clavefe: "", // Clave de la factura electrónica (vacío en este caso)
                details_json: DataPymentPneding, // Detalles en formato JSON del pago pendiente
                total: totalPayment, // Monto total del pago
                status: "pendiente", // Estado de la transacción (pendiente)
                transBank: "", // Información de la transacción bancaria (vacío en este caso)
                type: 1, // Tipo de transacción (1 para compra)
            });

            // Parámetros comunes para la transacción
            const commonParams = {
                idSesion: tokenSessions, // ID de la sesión
                cant: step3.seating.length, // Cantidad de asientos seleccionados
                nombreCliente: `${name_user} ${lasname_user}`, // Nombre completo del cliente
                cedulaCliente: indetification_user || "000", // Identificación del cliente o "000" si no está disponible
            };

            // Procesar los artículos comprados en la confitería
            await processArticles(step4.articules, tokenSessions);

            // Manejar la transacción con los parámetros comunes
            await handleTransaction(commonParams, 1, 0);

            // Devolver "ok" si todo salió bien
            return "ok";
        } catch (error) {
            // En caso de error, devolver "no"
            return "no";
        }
    };
};
/************************************************************************************************************************************************************************************/

/************************************************************************************************************************************************************************************/
/* SOLO CONFITERIA SE EJECUTA FIRME Y TEMPORAL********************************************************/

const createInvoice = async (crearTemporal, aplicarTemporal, tokenSessions, name_user, indetification_user, commonParams) => {
    return await create_Invoice_tem_Cineverse({
        ...commonParams,
        idSesion: tokenSessions,
        company: 0,
        branch: 0,
        cant: 0, // DEBEN IR VACIOS
        nombreCliente: name_user, // OBLIGATORIO
        cedulaCliente: indetification_user || "000", //OBLIGATORIO
        crear_temporal: crearTemporal, // YA NO APLICA  DEBE IR EN CERO
        aplicar_temporal: aplicarTemporal, // PARA QUE SE GENERE FACTURA EN FIRME
    });
};

export const trasnsactionConfiteriaTemporal = () => {
    return async (dispatch, getState) => {
        try {
            const { tokenSessions, step2, step3, step4, activeTanda, payment } = getState().checkout; // Estado de checkout
            const { id_user, name_user, email_user, status_user, lasname_user, phone_user, indetification_user, birthdate, token_user, mensajeError, mensajeErrorLogin, type_user } = getState().auth; // Estado de autenticación
            const { arrayInfoMovie } = getState().home; // Estado de la página principal (home)

            // Crear un array con la información del usuario
            const userArray = [id_user, name_user, email_user, status_user, lasname_user, phone_user, indetification_user, birthdate, token_user, mensajeError, mensajeErrorLogin, type_user];

            // Limpiar datos temporales en el servidor usando el token de la sesión
            await clearTemporaryData(tokenSessions);

            // Crear un objeto con los datos de pago pendientes
            const DataPymentPneding = {
                infoCLient: userArray, // Información del cliente
                priceList: step2, // Información de la lista de precios (asientos)
                seating: step3, // Información de los asientos seleccionados
                articules: step4.articules, // Información de los artículos comprados en la confitería
                movieData: activeTanda, // Información de la tanda de la película
                tokenUser: tokenSessions, // Token de la sesión del usuario
                payments: payment, // Información del pago
                transBank: "", // Información de la transacción bancaria (vacío en este caso)
                movieDetail: arrayInfoMovie, // Detalles adicionales de la película
                FeeServiceAmount: FeeServiceAmount,
            };

            const totalSubTotal = step4.articules.reduce((acc, producto) => acc + producto.subTotal, 0);
            //console.log("totalSubTotal", totalSubTotal);

            // Insertar un intento de pago con los datos recopilados
            await insertPaymentAttemptAsync({
                idSession: tokenSessions, // ID de la sesión
                idInvoice: "", // ID de la factura (vacío en este caso)
                clavefe: "", // Clave de la factura electrónica (vacío en este caso)
                details_json: DataPymentPneding, // Detalles en formato JSON del pago pendiente
                total: totalSubTotal, // Monto total del pago
                status: "pendiente", // Estado de la transacción (pendiente)
                transBank: "", // Información de la transacción bancaria (vacío en este caso)
                type: 2, // Tipo de transacción (1 para compra)
            });
            //console.log(tokenSessions);

            // Parámetros comunes para la transacción
            const commonParams = {
                idSesion: tokenSessions, // ID de la sesión
                cant: 0, // Cantidad de asientos seleccionados
                nombreCliente: `${name_user} ${lasname_user}`, // Nombre completo del cliente
                cedulaCliente: indetification_user || "000", // Identificación del cliente o "000" si no está disponible
            };

            // Procesar los artículos comprados en la confitería
            await processArticlesArticulos(step4.articules, tokenSessions);
            await createInvoice(1, 0, tokenSessions, name_user, indetification_user, commonParams);

            // Devolver "ok" si todo salió bien
            return "ok";
        } catch (error) {
            //   console.log("Error en trasnsactionPayment:", error);
            //  // Manejar cualquier error que ocurra durante el proceso
            // console.error("Error en trasnsactionPayment:", error);
            return "no";
        }
    };
};

export const trasnsactionConfiteriaFirme = () => {
    return async (dispatch, getState) => {
        try {
            // Primero, extraemos la información necesaria del estado de Redux
            const { tokenSessions, step2, step3, step4, activeTanda, payment } = getState().checkout; // Estado de checkout
            const { id_user, name_user, email_user, status_user, lasname_user, phone_user, indetification_user, birthdate, token_user, mensajeError, mensajeErrorLogin, type_user } = getState().auth; // Estado de autenticación
            const { arrayInfoMovie } = getState().home; // Estado de la página principal (home)

            // console.log("token", token_user);
            // Creamos un array con la información del usuario
            const userArray = [id_user, name_user, email_user, status_user, lasname_user, phone_user, indetification_user, birthdate, token_user, mensajeError, mensajeErrorLogin, type_user];

            // Obtenemos el mensaje encriptado almacenado en el localStorage
            const encryptedMessage = localStorage.getItem("pytmi");

            // Array para almacenar la información desencriptada
            const infopytmi = [];
            // Variable para almacenar el ID de la transacción
            let transactionId = 0;

            // Verificamos si hay un mensaje encriptado
            if (encryptedMessage) {
                // Desencriptamos el mensaje usando la clave secreta
                const decryptedMessage = CryptoJS.AES.decrypt(encryptedMessage, secretKey).toString(CryptoJS.enc.Utf8);

                // Parseamos el mensaje desencriptado a un objeto
                const parsedMessage = JSON.parse(decryptedMessage);

                // Extraemos el código de respuesta y otros datos del mensaje desencriptado
                const responseCode = parseInt(parsedMessage.responseCode, 10);
                const messageObj = JSON.parse(decryptedMessage);
                transactionId = messageObj.transactionid; // Asignamos el ID de la transacción

                // Agregamos la información desencriptada al array `infopytmi`
                infopytmi.push({
                    parsedMessage: parsedMessage, // Mensaje parseado
                    responseCode: responseCode, // Código de respuesta
                    messageObj: messageObj, // Objeto del mensaje
                    transactionId: transactionId, // ID de la transacción
                });
            }

            // Definimos los parámetros comunes para la transacción
            const commonParams = {
                idSesion: tokenSessions, // ID de la sesión
                cant: 0, // Cantidad de asientos seleccionados
                nombreCliente: `${name_user} ${lasname_user}`, // Nombre completo del cliente
                cedulaCliente: indetification_user || "000", // Identificación del cliente o "000" si no está disponible
            };

            // Aplicar la transacción temporal y obtener el ID de la factura
            const returnInv = await createInvoice(0, 1, tokenSessions, name_user, indetification_user, commonParams);
            // console.log("returnInvoi", returnInv);
            // Extraer el ID de la factura de la respuesta
            const invoiceIdBp = returnInv?.data?.data?.[0]?.Invoice_Id;
            // console.log("invoiceIdBp", invoiceIdBp);

            let estadoCompra = "pendiente";
            let bussisnes_pro = "Pendiente bussisnes_pro";
            let banck = "Pending del banco";
            let invoiceTemp = 0;

            // Actualizar estados basado en condiciones
            if (invoiceIdBp || transactionId > 0) {
                estadoCompra = "aceptado";
            }

            if (invoiceIdBp) {
                bussisnes_pro = "aceptado";
                invoiceTemp = invoiceIdBp;
            }

            if (transactionId > 0) {
                banck = "aceptado";
            }

            const DataPymentPneding = {
                infoCLient: userArray, // Información del cliente
                priceList: step2, // Información de la lista de precios (asientos)
                seating: step3, // Información de los asientos seleccionados
                articules: step4.articules, // Información de los artículos comprados en la confitería
                movieData: activeTanda, // Información de la tanda de la película
                tokenUser: tokenSessions, // Token de la sesión del usuario
                payments: payment, // Información del pago
                transBank: transactionId, // ID de la transacción bancaria
                movieDetail: arrayInfoMovie, // Detalles adicionales de la película
                FeeServiceAmount: FeeServiceAmount,
                bussisnes_pro: bussisnes_pro,
                banck: banck,
                infopytmi,
            };

            // Actualizamos la transacción del cliente con los datos recopilados
            await updateClientTransactionAsync({
                idSession: tokenSessions, // ID de la sesión
                idInvoice: invoiceTemp, // ID de la factura
                clavefe: "0", // Clave de la factura electrónica (0 en este caso)
                status: estadoCompra, // Estado de la transacción (aceptado)
                transBank: transactionId, // ID de la transacción bancaria
                DataPymentPneding: DataPymentPneding, // Datos de pago pendientes
            });

            // Información a enviar al insertar la factura
            const info = {
                invoiceIdBp: invoiceTemp, // ID de la factura
                transactionId: transactionId, // ID de la transacción
                infopytmi, // Información desencriptada del pago
            };

            // Insertamos la factura en la base de datos
            dispatch(insertFactura(info));

            if (invoiceIdBp || transactionId > 0) {
                await updateSessionStatusAsync({ status: 1, sessionId: tokenSessions });
            }
            if (invoiceIdBp) {
                // Devolvemos el ID de la factura y un ID de transacción ficticio (100)
                return { invoiceIdBp, transactionId: transactionId };
            }
        } catch (error) {
            // En caso de error, mostramos un mensaje de error en la consola y devolvemos null
            // console.error("Error en trasnsactionPayment:", error);
            return { invoiceIdBp: "", transactionId: "" };
        }
    };
};

/* extraer factura de la base de datos  ********************************************************/
export const getInvoiceId = (idInvoice) => {
    return async (dispatch, getState) => {
        try {
            const result = await getInvoiceIdAsync({ idInvoice });
            return result.data;
        } catch (error) {
            // Manejo de errores en caso de fallo al seleccionar la butaca
            //console.error("Error deselectSeat", error);
        }
    };
};

export const insertartFacturaErrorBanck = () => {
    return async (dispatch, getState) => {
        try {
            // Primero, extraemos la información necesaria del estado de Redux
            const { tokenSessions, step2, step3, step4, activeTanda, payment } = getState().checkout; // Estado de checkout
            const { id_user, name_user, email_user, status_user, lasname_user, phone_user, indetification_user, birthdate, token_user, mensajeError, mensajeErrorLogin, type_user } = getState().auth; // Estado de autenticación
            const { arrayInfoMovie } = getState().home; // Estado de la página principal (home)

            // Creamos un array con la información del usuario
            const userArray = [id_user, name_user, email_user, status_user, lasname_user, phone_user, indetification_user, birthdate, token_user, mensajeError, mensajeErrorLogin, type_user];

            // Obtenemos el mensaje encriptado almacenado en el localStorage
            const encryptedMessage = localStorage.getItem("pytmi");

            // Array para almacenar la información desencriptada
            const infopytmi = [];
            // Variable para almacenar el ID de la transacción
            let transactionId = 0;

            // Verificamos si hay un mensaje encriptado
            if (encryptedMessage) {
                // Desencriptamos el mensaje usando la clave secreta
                const decryptedMessage = CryptoJS.AES.decrypt(encryptedMessage, secretKey).toString(CryptoJS.enc.Utf8);

                // Parseamos el mensaje desencriptado a un objeto
                const parsedMessage = JSON.parse(decryptedMessage);

                // Extraemos el código de respuesta y otros datos del mensaje desencriptado
                const responseCode = parseInt(parsedMessage.responseCode, 10);
                const messageObj = JSON.parse(decryptedMessage);
                transactionId = messageObj.transactionid; // Asignamos el ID de la transacción

                // Agregamos la información desencriptada al array `infopytmi`
                infopytmi.push({
                    parsedMessage: parsedMessage, // Mensaje parseado
                    responseCode: responseCode, // Código de respuesta
                    messageObj: messageObj, // Objeto del mensaje
                    transactionId: transactionId, // ID de la transacción
                });
            }

            const invoiceIdBp = ""; // Obtenemos el ID de la factura

            // Inicializar estados
            let estadoCompra = "Pago no procesado";
            let bussisnes_pro = "Pago no procesado";
            let banck = "Pago no procesado";
            let invoiceTemp = 0;

            // Creamos un objeto con los datos de pago pendientes
            const DataPymentPneding = {
                infoCLient: userArray, // Información del cliente
                priceList: step2, // Información de la lista de precios (asientos)
                seating: step3, // Información de los asientos seleccionados
                articules: step4.articules, // Información de los artículos comprados en la confitería
                movieData: activeTanda, // Información de la tanda de la película
                tokenUser: tokenSessions, // Token de la sesión del usuario
                payments: payment, // Información del pago
                transBank: transactionId, // ID de la transacción bancaria
                movieDetail: arrayInfoMovie, // Detalles adicionales de la película
                FeeServiceAmount: FeeServiceAmount,
                bussisnes_pro: bussisnes_pro,
                banck: banck,
                infopytmi,
            };

            // Actualizamos la transacción del cliente con los datos recopilados
            await updateClientTransactionAsync({
                idSession: tokenSessions, // ID de la sesión
                idInvoice: invoiceTemp, // ID de la factura
                clavefe: "0", // Clave de la factura electrónica (0 en este caso)
                status: "pendiente", // Estado de la transacción (aceptado)
                transBank: transactionId, // ID de la transacción bancaria
                DataPymentPneding: DataPymentPneding, // Datos de pago pendientes
            });

            // Información a enviar al insertar la factura
            const info = {
                invoiceIdBp: invoiceTemp, // ID de la factura
                transactionId: transactionId, // ID de la transacción
                infopytmi, // Información desencriptada del pago
            };
            // Insertamos la factura en la base de datos
            dispatch(insertFactura(info));
        } catch (error) {
            // En caso de error, mostramos un mensaje de error en la consola y devolvemos null
            //  console.error("Error en trasnsactionPayment:", error);
            return { invoiceIdBp: "", transactionId: "" };
        }
    };
};



/* vamos a validar si existe la infrimacion tempral de la pelicula seleccionada*/
export const geTemporaryPayment = (movieValue) => {
    return async (dispatch, getState) => {
        try {
            const result = await geTemporaryPaymentFunctionAsync({ movieValue });
            return result;
        } catch (error) {
            return [];
        }
    };
};


export const geTemporaryPaymentInsert = (dataHome, dataChekout, dataAuth,banckInfo, setepPyme, movieValue, fecha, hora) => {
    return async (dispatch, getState) => {
        try {
            const result = await geTemporaryPaymentInsertFunctionAsync({ dataHome, dataChekout, dataAuth, banckInfo, setepPyme, movieValue, fecha, hora, banckInfo });
            return result;
        } catch (error) {
            return [];
        }
    };
};

export const geTemporaryPaymentUpdate = (dataHome, dataChekout, dataAuth, banckInfo, setepPyme, movieValue) => {
    return async (dispatch, getState) => {
        try {
            const result = await geTemporaryPaymentUpdateFunctionAsync({ dataHome, dataChekout, dataAuth, banckInfo, setepPyme, movieValue });
            return result;
        } catch (error) {
            await errorMessage(error);
            return [];
        }
    };
};