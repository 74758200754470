import { Box, useTheme, useMediaQuery } from "@mui/material";


function Publicidad() {
    const theme = useTheme(); // Obtiene el tema actual
    const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Determina si es móvil basado en el tamaño de la pantalla
    const isTablet = useMediaQuery("(max-width:1024px)"); // Verifica si la pantalla es móvil
    const isSurface = useMediaQuery("(max-width:1370px)"); // Verifica si la pantalla es móvil
    return (
        <>
            <Box style={{ width: "100%", height: isMobile ? "100px" : isTablet ? "225px" : isSurface ? "376px" : "491px", backgroundSize: "cover", backgroundImage: "url(/content/img/SC/bannerPublicidad.jpg)" }}>

            </Box>
        </>
    );
}

export default Publicidad;
