import { commonRequestData, fetchData } from "../../api";

export const getConsolidatedInfoAsync = async ({ getStatus }) => {
    // cuerpo de la solicitud que se enviará al servidor.
    const requestData = {
        ...commonRequestData,
        getStatus,
    };
    // Realiza una solicitud utilizando fetchData y retorna la respuesta
    return await fetchData("cineverse/getAllMoviesDataBaseCineverse", requestData);
};


export const getTandasBdAsync = async ({ fechaTanda, horaEnvio, activeMovieId }) => {
    // cuerpo de la solicitud que se enviará al servidor.
    const requestData = {
        ...commonRequestData,
        fechaTanda,
        horaEnvio,
        activeMovieId,
    };
    // Realiza una solicitud utilizando fetchData y retorna la respuesta
    return await fetchData("cineverse/getShiftsByMasterMovieIdAndDate", requestData);
};



export const selectSeatAsync = async ({ sala_id, butaca_id, esquema_id, esquema_detalle_id, fecha, pelicula_id, pelicula_nombre, idSesion}) => {
    // cuerpo de la solicitud que se enviará al servidor.
    const requestData = {
        ...commonRequestData,
        sala_id,
        butaca_id,
        esquema_id,
        esquema_detalle_id,
        fecha,
        pelicula_id,
        pelicula_nombre,
        idSesion,
    };
    // Realiza una solicitud utilizando fetchData y retorna la respuesta
    return await fetchData("cineverse/selectSeat", requestData);
};




export const deselectSeatAsync = async ({ sala_id, butaca_id, esquema_id, esquema_detalle_id, fecha, pelicula_id, pelicula_nombre, idSesion }) => {
    // cuerpo de la solicitud que se enviará al servidor.
    const requestData = {
        ...commonRequestData,
        sala_id,
        butaca_id,
        esquema_id,
        esquema_detalle_id,
        fecha,
        pelicula_id,
        pelicula_nombre,
        idSesion,
    };
    // Realiza una solicitud utilizando fetchData y retorna la respuesta
    return await fetchData("cineverse/deselectSeat", requestData);
};



export const AvailabledaysAsync = async ({ moviesId }) => {
    // cuerpo de la solicitud que se enviará al servidor.
    const requestData = {
        ...commonRequestData,
        moviesId,
    };
    // Realiza una solicitud utilizando fetchData y retorna la respuesta
    return await fetchData("cineverse/AvailabledaysAsync", requestData);
};


export const apiActualizarInfoCompra = async ({ idSession, details_json }) => {
    // cuerpo de la solicitud que se enviará al servidor.
    const requestData = {
        ...commonRequestData,
        idSession, // ID de la sesión de película
        details_json, // Detalles en formato JSON de la compra pendiente
    };
    // Realiza una solicitud utilizando fetchData y retorna la respuesta
    return await fetchData("cineverse/apiActualizarInfoCompra", requestData);
};