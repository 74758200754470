import { apidescargaInvoice, delete_Temporal_InvoiceAsync, delete_Temporal_transactionAsync, envioCorreoError } from "./Api_pymentStoree"; // Importa las funciones para eliminar la factura y transacción temporal desde la API de pago.
import { FeeServiceAmount, errorMessages, secretKey } from "../../api"; // Importa valores y mensajes de error necesarios.
import { isPageError210, isPageError500, seterrorMessage } from "../home"; // Funciones de manejo de errores en la página.
import { apiActualizarInfoCompra, deselectSeatAsync, selectSeatAsync } from "../home/Api_Movies_Provider"; // Funciones relacionadas con la selección de asientos.
import CryptoJS from "crypto-js"; // Librería para realizar operaciones criptográficas (opcional dependiendo de su uso en otro lugar del código).

/* Nuevas importaciones */
import { create_Invoice_tem_Cineverse, geTemporaryPaymentFunctionAsync, insertCHiledLine, insertFatherLine, insertPaymentAttemptAsync, trasnsactionPaymentAsync, updateClientTransactionAsync, updateSessionStatusAsync } from "../checkout/Api_checkout_Provider"; // Función asíncrona que maneja el pago temporal en el checkout.

const createInvoice = async (crearTemporal, aplicarTemporal, tokenSessions, name_user, indetification_user, commonParams) => {
    return await create_Invoice_tem_Cineverse({
        ...commonParams,
        idSesion: tokenSessions,
        company: 0,
        branch: 0,
        cant: 0, // DEBEN IR VACIOS
        nombreCliente: name_user, // OBLIGATORIO
        cedulaCliente: indetification_user || "000", //OBLIGATORIO
        crear_temporal: crearTemporal, // YA NO APLICA  DEBE IR EN CERO
        aplicar_temporal: aplicarTemporal, // PARA QUE SE GENERE FACTURA EN FIRME
    });
};

/**
 * Función para limpiar los datos temporales relacionados con la sesión de pago.
 * @param {string} tokenSessions - Identificador único de la sesión.
 * @returns {Promise<void>} - Retorna una promesa que se resuelve cuando los datos temporales se eliminan.
 */
const clearTemporaryData = async (tokenSessions) => {
    // Llama a la API para eliminar la factura temporal asociada a la sesión.
    await delete_Temporal_InvoiceAsync({ idSesion: tokenSessions });

    // Llama a la API para eliminar la transacción temporal asociada a la sesión.
    await delete_Temporal_transactionAsync({ idSesion: tokenSessions });
};


export const errorMessage = async (error) => {
    // Verificar si el error contiene la propiedad `message` y `stack`
    const errorMessage = error?.message || "Unknown error message"; // Capturar mensaje de error
    const errorStack = error?.stack || "No stack trace available"; // Capturar stack trace

    // Obtener la URL actual para incluirla en el reporte
    const currentUrl = window.location.href;

    // Obtener información adicional del stack trace
    let functionName = "Unknown function";
    let lineNumber = "Unknown line number";
    let columnNumber = "Unknown column number";

    // Intentar analizar el stack trace para obtener el nombre de la función, línea y columna
    if (error?.stack) {
        // Extraer la primera línea del stack trace (donde ocurrió el error)
        const stackLines = error.stack.split("\n");
        if (stackLines.length > 1) {
            const errorDetails = stackLines[1]; // Suele ser la segunda línea que tiene más info
            const match = errorDetails.match(/at\s+(.*)\s+\((.*):(\d+):(\d+)\)/) || errorDetails.match(/at\s+(.*):(\d+):(\d+)/);
            if (match) {
                functionName = match[1] || functionName;
                lineNumber = match[3] || lineNumber;
                columnNumber = match[4] || columnNumber;
            }
        }
    }

    // Estructurar el objeto de información de error
    const errorInfo = {
        errorMessage, // Mensaje del error
        stack: errorStack, // Traza del stack
        url: currentUrl, // URL actual de la aplicación donde ocurrió el error
        functionName, // Nombre de la función donde ocurrió el error
        lineNumber, // Línea del error
        columnNumber, // Columna del error
        userAgent: navigator.userAgent, // Información del navegador
        timestamp: new Date().toISOString(), // Marca de tiempo del error
    };

    // Convertir el objeto de error a un string JSON legible con indentación
    const errorInfoJson = JSON.stringify(errorInfo, null, 2);

    // Enviar el correo con la información del error
    await envioCorreoError({
        infoData: errorInfoJson, // Enviar el JSON como un string
    });
};



/**
 * Función para generar el pago temporal, la cual utiliza la función asíncrona geTemporaryPaymentFunctionAsync.
 * @param {Object} movieValue - Objeto con los valores de la película que se está procesando.
 * @returns {Function} - Retorna una función que ejecuta la lógica del pago temporal.
 */
export const geTemporaryPayment = (movieValue) => {
    return async (dispatch, getState) => {
        try {
            // Llama a la función que genera el pago temporal y espera su respuesta.
            const result = await geTemporaryPaymentFunctionAsync({ movieValue });

            // Si es necesario, despacha el resultado (en este caso, simplemente lo retorna).
            return result;
        } catch (error) {
             await errorMessage(error);
            return []; // Retorna un array vacío en caso de error para mantener la consistencia.
        }
    };
};

const handleTransaction = async (commonParams, cat_id_pricelist, list_Id_pricelist, createTemp, applyTemp, peopleCantidad) => {
    return await trasnsactionPaymentAsync({
        commonParams,
        cat_id_pricelist,
        list_Id_pricelist,
        createTemp,
        applyTemp,
        peopleCantidad,
    });
};

const processArticles = async (articles, tokenSessions, cantEntradas) => {
    // const numero = cantEntradas === 1 ? 3 : cantEntradas + 1; // Número base para calcular el número de línea
    // Inicializa el contador fuera del bucle
    let numero = 3;

    // Procesa los datos principales de los artículos
    const primaryProcessingPromises = articles.map((article, indexOff) =>
        article.sp_bp_ecext.map(async (sp) => {
            if (sp.SP_padre === 1) {
                const numeroLinea = numero; // Usar el valor actual de numero
                // console.log("numeroLinea", numeroLinea);
                numero += 1;

                const commonData = {
                    idSesion: tokenSessions, // Identificador de sesión
                    product_id: sp.SP_idproduct, // ID del producto
                    product_name: sp.SP_nameProduct, // Nombre del producto
                    product_price1: sp.SP_subTotalProduct, // Precio del producto
                    cantidad: sp.SP_cantidad, // Cantidad de productos
                    product_combo: sp.SP_isCombo, // Indica si es un combo
                    presentation_type: sp.SP_presentacion, // Tipo de presentación
                    line: numeroLinea, // Número de línea calculado
                };

                // Inserta la línea del padre
                await insertFatherLine(commonData);

                // Procesa las escogencias del producto padre
                await Promise.all(
                    sp.SP_escogencias.map(async (escogencia) => {
                        const grupo = escogencia.group ?? escogencia.groupDrinks ?? escogencia.groupExtras; // Selecciona el grupo correspondiente
                        await insertCHiledLine({
                            idSesion: tokenSessions, // Identificador de sesión
                            line: numeroLinea, // Número de línea del padre
                            id_padre: sp.SP_idproduct, // ID del producto padre
                            name_combo: sp.SP_nameProduct, // Nombre del combo
                            product_detail: escogencia.id, // Detalle del producto escogido
                            nombre_escogencia: escogencia.name, // Nombre de la escogencia
                            grupo, // Grupo seleccionado
                        });
                    }),
                );
            }
        }),
    );

    // Espera a que todas las promesas de procesamiento primario se completen
    await Promise.all(primaryProcessingPromises.flat());

    // Procesa los artículos que no son parte de un combo (SP_padre === 0)
    const soloIndividuales = articles.map((article, indexOff) =>
        article.sp_bp_ecext.map(async (sp) => {
            const numeroLinea = sp.SP_number_line + 100 + indexOff; // Calcula el número de línea basado en el índice del artículo más un offset
            const commonData = {
                idSesion: tokenSessions, // Identificador de sesión
                product_id: sp.SP_idproduct, // ID del producto
                product_name: sp.SP_nameProduct, // Nombre del producto
                product_price1: sp.SP_subTotalProduct, // Precio del producto
                cantidad: sp.SP_cantidad, // Cantidad de productos
                product_combo: sp.SP_isCombo, // Indica si es un combo
                presentation_type: sp.SP_presentacion, // Tipo de presentación
                line: numeroLinea, // Número de línea calculado
            };

            if (sp.SP_padre === 0) {
                // Verifica si el producto no es un padre
                await insertFatherLine(commonData); // Inserta la línea del producto individual
            }
        }),
    );

    // Espera a que todas las promesas de procesamiento de productos individuales se completen
    await Promise.all(soloIndividuales.flat());

    // Procesa los elementos individuales si son parte de un combo
    const individualProcessingPromises = articles.map((article, indexOff) =>
        article.sp_bp_ecext.map(async (sp) => {
            if (sp.SP_padre === 1) {
                // Verifica si el producto es un padre (parte de un combo)
                await Promise.all(
                    sp.SP_individaules.map(async (individual) => {
                        const sumaLine = sp.SP_number_line + 200 + indexOff; // Calcula el número de línea para el producto individual
                        await insertFatherLine({
                            idSesion: tokenSessions, // Identificador de sesión
                            product_id: individual.id, // ID del producto individual
                            product_name: individual.name, // Nombre del producto individual
                            product_price1: individual.price, // Precio del producto individual
                            cantidad: 1, // Cantidad del producto individual (siempre 1)
                            product_combo: 0, // No es un combo
                            presentation_type: 0, // Tipo de presentación (por defecto 0)
                            line: sumaLine, // Número de línea calculado para el producto individual
                        });
                    }),
                );
            }
        }),
    );

    // Espera a que todas las promesas de procesamiento de productos individuales en combos se completen
    await Promise.all(individualProcessingPromises.flat());
};

const processArticlesArticulos = async (articles, tokenSessions, cant) => {
    // const numero = cantEntradas === 1 ? 3 : cantEntradas + 1; // Número base para calcular el número de línea
    // Inicializa el contador fuera del bucle
    let numero = 1;

    // Procesa los datos principales de los artículos
    const primaryProcessingPromises = articles.map((article, indexOff) =>
        article.sp_bp_ecext.map(async (sp) => {
            if (sp.SP_padre === 1) {
                const numeroLinea = numero; // Usar el valor actual de numero
                // console.log("numeroLinea", numeroLinea);
                numero += 1;

                const commonData = {
                    idSesion: tokenSessions, // Identificador de sesión
                    product_id: sp.SP_idproduct, // ID del producto
                    product_name: sp.SP_nameProduct, // Nombre del producto
                    product_price1: sp.SP_subTotalProduct, // Precio del producto
                    cantidad: sp.SP_cantidad, // Cantidad de productos
                    product_combo: sp.SP_isCombo, // Indica si es un combo
                    presentation_type: sp.SP_presentacion, // Tipo de presentación
                    line: numeroLinea, // Número de línea calculado
                };

                // Inserta la línea del padre
                const re = await insertFatherLine(commonData);

                // Procesa las escogencias del producto padre
                await Promise.all(
                    sp.SP_escogencias.map(async (escogencia) => {
                        const grupo = escogencia.group ?? escogencia.groupDrinks ?? escogencia.groupExtras; // Selecciona el grupo correspondiente
                        await insertCHiledLine({
                            idSesion: tokenSessions, // Identificador de sesión
                            line: numeroLinea, // Número de línea del padre
                            id_padre: sp.SP_idproduct, // ID del producto padre
                            name_combo: sp.SP_nameProduct, // Nombre del combo
                            product_detail: escogencia.id, // Detalle del producto escogido
                            nombre_escogencia: escogencia.name, // Nombre de la escogencia
                            grupo, // Grupo seleccionado
                        });
                    }),
                );
            }
        }),
    );

    // Espera a que todas las promesas de procesamiento primario se completen
    await Promise.all(primaryProcessingPromises.flat());

    // Procesa los artículos que no son parte de un combo (SP_padre === 0)
    const soloIndividuales = articles.map((article, indexOff) =>
        article.sp_bp_ecext.map(async (sp) => {
            const numeroLinea = sp.SP_number_line + 100 + indexOff; // Calcula el número de línea basado en el índice del artículo más un offset
            const commonData = {
                idSesion: tokenSessions, // Identificador de sesión
                product_id: sp.SP_idproduct, // ID del producto
                product_name: sp.SP_nameProduct, // Nombre del producto
                product_price1: sp.SP_subTotalProduct, // Precio del producto
                cantidad: sp.SP_cantidad, // Cantidad de productos
                product_combo: sp.SP_isCombo, // Indica si es un combo
                presentation_type: sp.SP_presentacion, // Tipo de presentación
                line: numeroLinea, // Número de línea calculado
            };

            if (sp.SP_padre === 0) {
                // Verifica si el producto no es un padre
                await insertFatherLine(commonData); // Inserta la línea del producto individual
            }
        }),
    );

    // Espera a que todas las promesas de procesamiento de productos individuales se completen
    await Promise.all(soloIndividuales.flat());

    // Procesa los elementos individuales si son parte de un combo
    const individualProcessingPromises = articles.map((article, indexOff) =>
        article.sp_bp_ecext.map(async (sp) => {
            if (sp.SP_padre === 1) {
                // Verifica si el producto es un padre (parte de un combo)
                await Promise.all(
                    sp.SP_individaules.map(async (individual) => {
                        const sumaLine = sp.SP_number_line + 200 + indexOff; // Calcula el número de línea para el producto individual
                        await insertFatherLine({
                            idSesion: tokenSessions, // Identificador de sesión
                            product_id: individual.id, // ID del producto individual
                            product_name: individual.name, // Nombre del producto individual
                            product_price1: individual.price, // Precio del producto individual
                            cantidad: 1, // Cantidad del producto individual (siempre 1)
                            product_combo: 0, // No es un combo
                            presentation_type: 0, // Tipo de presentación (por defecto 0)
                            line: sumaLine, // Número de línea calculado para el producto individual
                        });
                    }),
                );
            }
        }),
    );

    // Espera a que todas las promesas de procesamiento de productos individuales en combos se completen
    await Promise.all(individualProcessingPromises.flat());
};

/**
 * Función para insertar la factura temporal.
 * @param {Object} movieParam - Objeto con los parámetros de la película.
 * @returns {Function} - Retorna una función que inserta la factura temporal y maneja la lógica.
 */
export const insertartTempInvoice = (movieParam) => {
    return async (dispatch, getState) => {

        try {
            // Despacha la acción para obtener la información del pago temporal y extrae la respuesta.
            const { data } = await dispatch(geTemporaryPayment(movieParam));

            // Valida si el código de estado es diferente de 200 (éxito).
            if (data.statusCode !== 200) {
                errorMessage(new Error("No hay data disponible insertartTempInvoice"));
                return "error"; // Retorna "error" si el código de estado no es 200.
            }
            // Extrae los datos del usuario desde la respuesta obtenida.
            const [dataUser] = data.data;

            // Extrae el token de la sesión desde el estado del checkout.
            const checkoutState = dataUser.temporary_payment_data_checkoutState;
            const homeState = dataUser.temporary_payment_data_homeState;
            const datosUsuario = dataUser.temporary_payment_data_authUser.datosUsuario;

            // Llama a la función que elimina los datos temporales utilizando el token de sesión.
            await clearTemporaryData(checkoutState.tokenSessions);

            /* cantidad de personas en total */
            const totalquantityPeople = Math.max(checkoutState.precioDelista.quantityPeople, 0);

            // Calcular el total del paso 2 (asientos)
            const totalPriceList = totalquantityPeople > 0 ? checkoutState.precioDelista.totalStep2 : 0;

            // Calcular el subtotal de los artículos en el paso 4 (confitería)
            const totalSubTotal = checkoutState.articuloSeleccionado.reduce((acc, producto) => acc + producto.subTotal, 0);

            const FeeService = totalquantityPeople * FeeServiceAmount;

            // Calcular el total del pago incluyendo el costo del servicio y el total de asientos
            const totalPayment = totalSubTotal + FeeService + totalPriceList;

            const extraInformacion = homeState.extraInformacion[0];

            // Crear un objeto con los datos de pago pendientes
            const DataPymentPneding = {
                infoCLient: datosUsuario, // Información del cliente
                priceList: checkoutState.precioDelista, // Información de la lista de precios (asientos)
                seating: checkoutState.asientosSeleccionados, // Información de los asientos seleccionados
                articules: checkoutState.articuloSeleccionado, // Información de los artículos comprados en la confitería
                movieData: checkoutState.peliculaActiva, // Información de la tanda de la película
                tokenUser: checkoutState.tokenSessions, // Token de la sesión del usuario
                payments: checkoutState.payment, // Información del pago
                transBank: "", // Información de la transacción bancaria (vacío en este caso)
                movieDetail: extraInformacion, // Detalles adicionales de la película
                FeeServiceAmount: FeeServiceAmount,
                movieParam: movieParam,
            };

            await insertPaymentAttemptAsync({
                idSession: checkoutState.tokenSessions, // ID de la sesión
                idInvoice: "", // ID de la factura (vacío en este caso)
                clavefe: "", // Clave de la factura electrónica (vacío en este caso)
                details_json: DataPymentPneding, // Detalles en formato JSON del pago pendiente
                total: totalPayment, // Monto total del pago
                status: "pendiente", // Estado de la transacción (pendiente)
                transBank: "", // Información de la transacción bancaria (vacío en este caso)
                type: 1, // Tipo de transacción (1 para compra)
            });
            const commonParams = {
                idSesion: checkoutState.tokenSessions, // ID de la sesión
                cant: checkoutState.asientosSeleccionados.seating.length, // Cantidad de asientos seleccionados
                nombreCliente: `${datosUsuario.name_user} ${datosUsuario.lasname_user}`, // Nombre completo del cliente
                cedulaCliente: datosUsuario.indetification_user || "000", // Identificación del cliente o "000" si no está disponible
            };

            let cantEntradas = 0;

            await processArticles(checkoutState.articuloSeleccionado, checkoutState.tokenSessions, cantEntradas);

            async function processTransactions() {
                for (const item of checkoutState.precioDelista.priceList) {
                    if (item.quantity > 0) {
                        cantEntradas += item.quantity;
                        // Verifica si la cantidad es mayor a cero
                        const cat_id_pricelist = item.cat_id_pricelist_bp;
                        const list_Id_pricelist = item.list_Id_pricelist_bp;
                        const createTemp = 1;
                        const applyTemp = 0;
                        const peopleCantidad = item.quantity;
                        await handleTransaction(commonParams, cat_id_pricelist, list_Id_pricelist, createTemp, applyTemp, peopleCantidad);
                    }
                }
            }

            await processTransactions();

            // await handleTransaction(commonParams, 0, 0, 1, 0, checkoutState.articuloSeleccionado.length);
            return "ok"; // Retorna "ok" si todo el proceso fue exitoso.
        } catch (error) {
             await errorMessage(error);
            // Muestra un error en la consola si ocurre algún fallo en el proceso.
            // console.error("Error inserting temporary invoice", error);
            return "no"; // Retorna "no" en caso de error para indicar el fallo.
        }
    };
};

/**
 * Función para insertar la factura temporal.
 * @param {Object} movieParam - Objeto con los parámetros de la película.
 * @returns {Function} - Retorna una función que inserta la factura temporal y maneja la lógica.
 */
export const insertartTempInvoiceAgain = (movieParam) => {
    return async (dispatch, getState) => {
        try {
            // Despacha la acción para obtener la información del pago temporal y extrae la respuesta.
            const { data } = await dispatch(geTemporaryPayment(movieParam));
            // Valida si el código de estado es diferente de 200 (éxito).
            if (data.statusCode !== 200) {
                const info = movieParam;
                const dataInfo = data;
                errorMessage(new Error(`No hay data disponible insertartTempInvoiceAgain. Información de la película: ${info}. Datos recibidos: ${JSON.stringify(dataInfo)}`));
                return "error"; // Retorna "error" si el código de estado no es 200.
            }
            // Extrae los datos del usuario desde la respuesta obtenida.
            const [dataUser] = data.data;
            // Extrae el token de la sesión desde el estado del checkout.
            const checkoutState = dataUser.temporary_payment_data_checkoutState;
            const datosUsuario = dataUser.temporary_payment_data_authUser.datosUsuario;

            // Llama a la función que elimina los datos temporales utilizando el token de sesión.
            await clearTemporaryData(checkoutState.tokenSessions);

            const commonParams = {
                idSesion: checkoutState.tokenSessions, // ID de la sesión
                cant: checkoutState.asientosSeleccionados.seating.length, // Cantidad de asientos seleccionados
                nombreCliente: `${datosUsuario.name_user} ${datosUsuario.lasname_user}`, // Nombre completo del cliente
                cedulaCliente: datosUsuario.indetification_user || "000", // Identificación del cliente o "000" si no está disponible
            };

            let cantEntradas = 0;

            await processArticles(checkoutState.articuloSeleccionado, checkoutState.tokenSessions, cantEntradas);

            async function processTransactions() {
                for (const item of checkoutState.precioDelista.priceList) {
                    if (item.quantity > 0) {
                        cantEntradas += item.quantity;
                        // Verifica si la cantidad es mayor a cero
                        const cat_id_pricelist = item.cat_id_pricelist_bp;
                        const list_Id_pricelist = item.list_Id_pricelist_bp;
                        const createTemp = 1;
                        const applyTemp = 0;
                        const peopleCantidad = item.quantity;
                        await handleTransaction(commonParams, cat_id_pricelist, list_Id_pricelist, createTemp, applyTemp, peopleCantidad);
                    }
                }
            }

            await processTransactions();

            // await handleTransaction(commonParams, 0, 0, 1, 0, checkoutState.articuloSeleccionado.length);
            return "ok"; // Retorna "ok" si todo el proceso fue exitoso.
       } catch (error) {
            await errorMessage(error);
            return "no"; // Retorna "no" en caso de error para indicar el fallo.
        }
    };
};

/**
 * Función para insertar la factura temporal.
 * @param {Object} movieParam - Objeto con los parámetros de la película.
 * @returns {Function} - Retorna una función que inserta la factura temporal y maneja la lógica.
 */
export const insertartFirmeInvoice = (movieParam) => {
    return async (dispatch, getState) => {

        try {
            // Despacha la acción para obtener la información del pago temporal
            const { data } = await dispatch(geTemporaryPayment(movieParam));
            const [dataUser] = data.data;

            // Extraemos datos del usuario y estados de pago
            const checkoutState = dataUser.temporary_payment_data_checkoutState;
            const homeState = dataUser.temporary_payment_data_homeState;
            const datosUsuario = dataUser.temporary_payment_data_authUser.datosUsuario;
            const informacionDelbanco = dataUser.temporary_payment_data_banck;

            // Desencriptar información del pago
            let transactionId = informacionDelbanco.transactionid || 0;

            // Datos del cliente y otros valores predeterminados
            const cedulaUser = datosUsuario.indetification_user || "000";
            const nameUser = `${datosUsuario.name_user} ${datosUsuario.lasname_user}`;
            const cantseating = checkoutState.asientosSeleccionados.seating.length;

            // Parámetros comunes
            const commonParams = {
                idSesion: checkoutState.tokenSessions,
                cant: "ya no se usa",
                nombreCliente: nameUser,
                cedulaCliente: cedulaUser,
            };

            // Llamada a la transacción
            const returnInvoiceId = await handleTransaction(commonParams, 0, 0, 0, 1, 0);
            const invoiceIdBp = returnInvoiceId?.data?.data?.[0]?.Invoice_Id ?? 0;

            // Asignación de estado de la compra
            let estadoCompra = invoiceIdBp > 0 || transactionId > 0 ? "aceptado" : "pendiente";
            let bussisnes_pro = invoiceIdBp > 0 ? "aceptado" : "Pendiente bussisnes_pro";
            let banck = transactionId > 0 ? "aceptado" : "Pending del banco";

            const extraInformacion = homeState.extraInformacion[0];

            const DataPymentPneding = {
                infoCLient: datosUsuario,
                priceList: checkoutState.precioDelista,
                seating: checkoutState.asientosSeleccionados,
                articules: checkoutState.articuloSeleccionado,
                movieData: checkoutState.peliculaActiva,
                tokenUser: checkoutState.tokenSessions,
                payments: checkoutState.payment,
                transBank: transactionId,
                movieDetail: extraInformacion,
                FeeServiceAmount: FeeServiceAmount,
                bussisnes_pro,
                banck,
                errorBanck: informacionDelbanco,
                movieParam: movieParam,
            };

            // Actualización de la transacción del cliente
            await updateClientTransactionAsync({
                idSession: checkoutState.tokenSessions,
                idInvoice: invoiceIdBp || 0,
                clavefe: "0",
                status: estadoCompra,
                transBank: transactionId,
                DataPymentPneding,
            });

            // Actualización del estado de la sesión si es necesario
            if (transactionId > 0 || invoiceIdBp > 0) {
                await updateSessionStatusAsync({ status: 1, sessionId: checkoutState.tokenSessions });
            }

            // Retornar ID de factura y transacción
            return { invoiceIdBp, transactionId };
        } catch (error) {
             await errorMessage(error);
            return { invoiceIdBp: 0, transactionId: "" };
        }
    };
};

// Función para actualizar la información de compra de una sesión de película.
export const actualizarDatosCompra = (idSesionPelicula, detallesCompraJson = "") => {
    return async (dispatch, getState) => {
        try {
            // Llama a la API para modificar la información de compra con los detalles proporcionados
            await apiActualizarInfoCompra({
                idSession: idSesionPelicula, // ID de la sesión de película
                details_json: detallesCompraJson, // Detalles en formato JSON de la compra pendiente
            });
            return "ok"; // Retorna "ok" si la actualización se realiza correctamente.
       } catch (error) {
             await errorMessage(error);
            // Registra el error en la consola en caso de que falle el proceso de actualización.
            // console.error("Error al actualizar la información de compra", error);
            return "no"; // Retorna "no" en caso de error.
        }
    };
};

export const insertarErrorDeFactura = (movieValue, errorBanck) => {
    return async (dispatch, getState) => {
        try {
            // Despacha la acción para obtener la información del pago temporal y extrae la respuesta.
            const { data } = await dispatch(geTemporaryPayment(movieValue));


            // Valida si el código de estado es diferente de 200 (éxito).
            if (data.statusCode !== 200) {
                errorMessage(new Error("No hay data disponible insertarErrorDeFactura"));
                return "error"; // Retorna "error" si el código de estado no es 200.
            }
            // Extrae los datos del usuario desde la respuesta obtenida.
            const [dataUser] = data.data;

            // Extrae el token de la sesión desde el estado del checkout.
            const checkoutState = dataUser.temporary_payment_data_checkoutState;
            const homeState = dataUser.temporary_payment_data_homeState;
            const datosUsuario = dataUser.temporary_payment_data_authUser.datosUsuario;

            // Array para almacenar la información desencriptada
            const infopytmi = [];
            // Variable para almacenar el ID de la transacción
            let transactionId = "";
            const invoiceIdBp = ""; // Obtenemos el ID de la factura

            // Inicializar estados
            let estadoCompra = "Pago no procesado";
            let bussisnes_pro = "Pago no procesado";
            let banck = "Pago no procesado";
            let invoiceTemp = 0;

            const extraInformacion = homeState.extraInformacion[0];

            // Creamos un objeto con los datos de pago pendientes
            const DataPymentPneding = {
                infoCLient: datosUsuario, // Información del cliente
                priceList: checkoutState.precioDelista, // Información de la lista de precios (asientos)
                seating: checkoutState.asientosSeleccionados, // Información de los asientos seleccionados
                articules: checkoutState.articuloSeleccionado, // Información de los artículos comprados en la confitería
                movieData: checkoutState.peliculaActiva, // Información de la tanda de la película
                tokenUser: checkoutState.tokenSessions, // Token de la sesión del usuario
                payments: checkoutState.payment, // Información del pago
                transBank: transactionId, // ID de la transacción bancaria
                movieDetail: extraInformacion, // Detalles adicionales de la película
                FeeServiceAmount: FeeServiceAmount,
                bussisnes_pro: bussisnes_pro,
                banck: banck,
                errorBanck: errorBanck,
                movieValue: movieValue,
            };

            // Actualizamos la transacción del cliente con los datos recopilados
            await updateClientTransactionAsync({
                idSession: checkoutState.tokenSessions, // ID de la sesión
                idInvoice: invoiceTemp, // ID de la factura
                clavefe: "0", // Clave de la factura electrónica (0 en este caso)
                status: "pendiente", // Estado de la transacción (aceptado)
                transBank: transactionId, // ID de la transacción bancaria
                DataPymentPneding: DataPymentPneding, // Datos de pago pendientes
            });
            return "ok"; // Retorna "ok" si todo el proceso fue exitoso.
        } catch (error) {
             await errorMessage(error);
            return "no"; // Retorna "ok" si todo el proceso fue exitoso.
        }
    };
};

// Función para actualizar la información de compra de una sesión de película.
export const descargaInvoice = (idCompra) => {
    return async (dispatch, getState) => {
        try {
            // Llama a la API para modificar la información de compra con los detalles proporcionados
            await apidescargaInvoice({
                idCompra: idCompra, // ID de la sesión de película
            });
            return "ok"; // Retorna "ok" si la actualización se realiza correctamente.
        } catch (error) {
            // Registra el error en la consola en caso de que falle el proceso de actualización.
            //.error("Error al actualizar la información de compra", error);
            return "no"; // Retorna "no" en caso de error.
        }
    };
};

/**
 * Función para insertar la factura temporal.
 * @param {Object} movieParam - Objeto con los parámetros de la película.
 * @returns {Function} - Retorna una función que inserta la factura temporal y maneja la lógica.
 */
export const insertartTempInvoiceConfiteria = (movieParam) => {
    return async (dispatch, getState) => {
        try {
            // Despacha la acción para obtener la información del pago temporal y extrae la respuesta.
            const { data } = await dispatch(geTemporaryPayment(movieParam));

            // Valida si el código de estado es diferente de 200 (éxito).
            if (data.statusCode !== 200) {
                errorMessage(new Error("No hay data disponible insertartTempInvoiceConfiteria"));
                return "error"; // Retorna "error" si el código de estado no es 200.
            }
            // Extrae los datos del usuario desde la respuesta obtenida.
            const [dataUser] = data.data;

            // Extrae el token de la sesión desde el estado del checkout.
            const checkoutState = dataUser.temporary_payment_data_checkoutState;
            const homeState = dataUser.temporary_payment_data_homeState;
            const datosUsuario = dataUser.temporary_payment_data_authUser.datosUsuario;

            // console.log("movieParam", movieParam);
            // console.log("checkoutState", checkoutState.tokenSessions);

            // Llama a la función que elimina los datos temporales utilizando el token de sesión.
            await clearTemporaryData(checkoutState.tokenSessions);

            // Calcular el subtotal de los artículos en el paso 4 (confitería)
            const totalSubTotal = checkoutState.articuloSeleccionado.reduce((acc, producto) => acc + producto.subTotal, 0);

            // Calcular el total del pago incluyendo el costo del servicio y el total de asientos
            const totalPayment = totalSubTotal;

            const extraInformacion = homeState.extraInformacion[0] || {};

            // Crear un objeto con los datos de pago pendientes
            const DataPymentPneding = {
                infoCLient: datosUsuario, // Información del cliente
                priceList: checkoutState.precioDelista, // Información de la lista de precios (asientos)
                seating: checkoutState.asientosSeleccionados, // Información de los asientos seleccionados
                articules: checkoutState.articuloSeleccionado, // Información de los artículos comprados en la confitería
                movieData: checkoutState.peliculaActiva, // Información de la tanda de la película
                tokenUser: checkoutState.tokenSessions, // Token de la sesión del usuario
                payments: checkoutState.payment, // Información del pago
                transBank: "", // Información de la transacción bancaria (vacío en este caso)
                movieDetail: extraInformacion, // Detalles adicionales de la película
                FeeServiceAmount: FeeServiceAmount,
                movieParam: movieParam,
            };

            await insertPaymentAttemptAsync({
                idSession: checkoutState.tokenSessions, // ID de la sesión
                idInvoice: "", // ID de la factura (vacío en este caso)
                clavefe: "", // Clave de la factura electrónica (vacío en este caso)
                details_json: DataPymentPneding, // Detalles en formato JSON del pago pendiente
                total: totalPayment, // Monto total del pago
                status: "pendiente", // Estado de la transacción (pendiente)
                transBank: "", // Información de la transacción bancaria (vacío en este caso)
                type: 2, // Tipo de transacción (1 para compra)
            });

            const cedulaUser = datosUsuario.indetification_user || "000"; // Cédula del usuario o "000" si no está disponible
            const nameUser = `${datosUsuario.name_user} ${datosUsuario.lasname_user}`; // Nombre completo del usuario

            const commonParams = {
                idSesion: checkoutState.tokenSessions, // ID de la sesión
                cant: checkoutState.asientosSeleccionados.seating.length, // Cantidad de asientos seleccionados
                nombreCliente: `${datosUsuario.name_user} ${datosUsuario.lasname_user}`, // Nombre completo del cliente
                cedulaCliente: datosUsuario.indetification_user || "000", // Identificación del cliente o "000" si no está disponible
            };

            await processArticlesArticulos(checkoutState.articuloSeleccionado, checkoutState.tokenSessions, 0);
            await createInvoice(1, 0, checkoutState.tokenSessions, nameUser, cedulaUser, commonParams);

            return "ok"; // Retorna "ok" si todo el proceso fue exitoso.
        } catch (error) {
             await errorMessage(error);
            // Muestra un error en la consola si ocurre algún fallo en el proceso.
            // console.error("Error inserting temporary invoice", error);
            return "no"; // Retorna "no" en caso de error para indicar el fallo.
        }
    };
};

/**
 * Función para insertar la factura temporal.
 * @param {Object} movieParam - Objeto con los parámetros de la película.
 * @returns {Function} - Retorna una función que inserta la factura temporal y maneja la lógica.
 */
export const insertartFirmeInvoiceConfiteria = (movieParam) => {
    return async (dispatch, getState) => {
        try {
            // Despacha la acción para obtener la información del pago temporal y extrae la respuesta.
            const { data } = await dispatch(geTemporaryPayment(movieParam));

            // Extrae los datos del usuario desde la respuesta obtenida.
            const [dataUser] = data.data;

            // Extrae el token de la sesión desde el estado del checkout.
            const checkoutState = dataUser.temporary_payment_data_checkoutState;
            const homeState = dataUser.temporary_payment_data_homeState;
            const datosUsuario = dataUser.temporary_payment_data_authUser.datosUsuario;
            const informacionDelbanco = dataUser.temporary_payment_data_banck;

            // Variable para almacenar el ID de la transacción
            let transactionId = informacionDelbanco.transactionid || 0;

            const cedulaUser = datosUsuario.indetification_user || "000"; // Cédula del usuario o "000" si no está disponible
            const nameUser = `${datosUsuario.name_user} ${datosUsuario.lasname_user}`; // Nombre completo del usuario

            const commonParams = {
                idSesion: checkoutState.tokenSessions, // ID de la sesión
                cant: checkoutState.asientosSeleccionados.seating.length, // Cantidad de asientos seleccionados
                nombreCliente: `${datosUsuario.name_user} ${datosUsuario.lasname_user}`, // Nombre completo del cliente
                cedulaCliente: datosUsuario.indetification_user || "000", // Identificación del cliente o "000" si no está disponible
            };
            const returnInvoiceId = await createInvoice(0, 1, checkoutState.tokenSessions, nameUser, cedulaUser, commonParams);
            const invoiceIdBp = returnInvoiceId?.data?.data?.[0]?.Invoice_Id; // Obtenemos el ID de la factura

            let estadoCompra = "pendiente";
            let bussisnes_pro = "Pendiente bussisnes_pro";
            let banck = "Pending del banco";
            let invoiceTemp = 0;

            if (invoiceIdBp || transactionId > 0) {
                estadoCompra = "aceptado";
            }

            if (invoiceIdBp) {
                bussisnes_pro = "aceptado";
                invoiceTemp = invoiceIdBp;
            }

            if (transactionId > 0) {
                banck = "aceptado";
            }
            const extraInformacion = homeState.extraInformacion[0];
            const DataPymentPneding = {
                infoCLient: datosUsuario, // Información del cliente
                priceList: checkoutState.precioDelista, // Información de la lista de precios (asientos)
                seating: checkoutState.asientosSeleccionados, // Información de los asientos seleccionados
                articules: checkoutState.articuloSeleccionado, // Información de los artículos comprados en la confitería
                movieData: checkoutState.peliculaActiva, // Información de la tanda de la película
                tokenUser: checkoutState.tokenSessions, // Token de la sesión del usuario
                payments: checkoutState.payment, // Información del pago
                transBank: transactionId, // ID de la transacción bancaria
                movieDetail: extraInformacion, // Detalles adicionales de la película
                FeeServiceAmount: FeeServiceAmount,
                bussisnes_pro: bussisnes_pro,
                banck: banck,
                errorBanck: informacionDelbanco,
                movieParam: movieParam,
            };
            const rest = await updateClientTransactionAsync({
                idSession: checkoutState.tokenSessions, // ID de la sesión
                idInvoice: invoiceTemp, // ID de la factura
                clavefe: "0", // Clave de la factura electrónica (0 en este caso)
                status: estadoCompra, // Estado de la transacción (aceptado)
                transBank: transactionId, // ID de la transacción bancaria
                DataPymentPneding: DataPymentPneding, // Datos de pago pendientes
            });

            if (invoiceIdBp) {
                // Devolvemos el ID de la factura y un ID de transacción ficticio (100)
                return { invoiceIdBp, transactionId: transactionId };
            } else {
                return { invoiceIdBp: 0, transactionId: "" };
            }
       } catch (error) {
             await errorMessage(error);
            return { invoiceIdBp: 0, transactionId: "" };
        }
    };
};

export const insertartTempInvoiceConfiteriaAgain = (movieParam) => {
    return async (dispatch, getState) => {
        try {
            // Despacha la acción para obtener la información del pago temporal y extrae la respuesta.
            const { data } = await dispatch(geTemporaryPayment(movieParam));

            // Valida si el código de estado es diferente de 200 (éxito).
            if (data.statusCode !== 200) {
                errorMessage(new Error("No hay data disponible insertartTempInvoiceConfiteriaAgain"));
                return "error"; // Retorna "error" si el código de estado no es 200.
            }
            // Extrae los datos del usuario desde la respuesta obtenida.
            const [dataUser] = data.data;

            // Extrae el token de la sesión desde el estado del checkout.
            const checkoutState = dataUser.temporary_payment_data_checkoutState;
            const datosUsuario = dataUser.temporary_payment_data_authUser.datosUsuario;

            await clearTemporaryData(checkoutState.tokenSessions);

            const cedulaUser = datosUsuario.indetification_user || "000"; // Cédula del usuario o "000" si no está disponible
            const nameUser = `${datosUsuario.name_user} ${datosUsuario.lasname_user}`; // Nombre completo del usuario

            const commonParams = {
                idSesion: checkoutState.tokenSessions, // ID de la sesión
                cant: checkoutState.asientosSeleccionados.seating.length, // Cantidad de asientos seleccionados
                nombreCliente: `${datosUsuario.name_user} ${datosUsuario.lasname_user}`, // Nombre completo del cliente
                cedulaCliente: datosUsuario.indetification_user || "000", // Identificación del cliente o "000" si no está disponible
            };

            await processArticlesArticulos(checkoutState.articuloSeleccionado, checkoutState.tokenSessions, 0);
            await createInvoice(1, 0, checkoutState.tokenSessions, nameUser, cedulaUser, commonParams);

            return "ok"; // Retorna "ok" si todo el proceso fue exitoso.
        } catch (error) {
             await errorMessage(error);
            // Muestra un error en la consola si ocurre algún fallo en el proceso.
            // console.error("Error inserting temporary invoice", error);
            return "no"; // Retorna "no" en caso de error para indicar el fallo.
        }
    };
};
