import React, { useState, useEffect, useRef } from "react";
import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MessageDialog from "../componentsGlobal/MessageDialog";

// Convierte una hora en formato de 24 horas a formato de 12 horas con AM/PM
const convert24To12HourFormat = (timeString) => {
    let [hours, minutes, seconds] = timeString.split(":").map(Number);
    const period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    return `${hours < 10 ? "0" : ""}${hours}:${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""}${seconds} ${period}`;
};

export const CountdownTimer = ({ startTime, onRecalculateTime }) => {
    const themeR = useTheme();
    const isMobile = useMediaQuery(themeR.breakpoints.down("sm"));
    const navigate = useNavigate();

    // Calcula el tiempo restante basado en la hora de inicio
    const calculateTimeRemaining = (startTime) => {
        const currentDate = new Date();
        const [startHours, startMinutes, startSeconds] = startTime.split(":").map(Number);

        // Obtener la fecha actual y la fecha de inicio
        const startDate = new Date(currentDate);
        startDate.setHours(startHours, startMinutes, startSeconds, 0); // Establecer la hora de inicio en la fecha actual

        // Si la hora de inicio es mayor que la hora actual, significa que la hora de inicio es del día anterior
        if (startDate > currentDate) {
            startDate.setDate(startDate.getDate() - 1); // Restar un día a la hora de inicio
        }

        const elapsedSeconds = Math.floor((currentDate - startDate) / 1000); // Diferencia en segundos entre la hora actual y la hora de inicio
        const totalDurationInSeconds = 8 * 60; // 8 minutos
        const remainingTime = totalDurationInSeconds - elapsedSeconds;

        return remainingTime > 0 ? remainingTime : 0;
    };

    const [time, setTime] = useState(calculateTimeRemaining(startTime));
    const [lastActiveTime, setLastActiveTime] = useState(Date.now());
    const [showDialog, setShowDialog] = useState(false);
    const timerRef = useRef(null);

    // Efecto para recalcular el tiempo cuando la página vuelva a ser visible
    useEffect(() => {
        const handleVisibilityChange = () => {
            if (!document.hidden) {
                setTime(calculateTimeRemaining(startTime)); // Recalcula el tiempo al volver
            }
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);

        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, [startTime]);

    // Efecto para reducir el tiempo restante cada segundo
    useEffect(() => {
        timerRef.current = setInterval(() => {
            setTime((prevTime) => {
                const newTime = calculateTimeRemaining(startTime); // Calcula el tiempo real restante
                if (newTime > 0) {
                    return newTime;
                } else {
                    clearInterval(timerRef.current);
                    setShowDialog(true); // Muestra el diálogo cuando el tiempo se acabe
                    return 0;
                }
            });
        }, 1000);

        return () => clearInterval(timerRef.current);
    }, [startTime]);

    // Redirigir al usuario después de 6 segundos cuando el tiempo se acaba
    useEffect(() => {
        if (showDialog) {
            const redirectTimeout = setTimeout(() => {
                navigate("/");
            }, 4000);

            return () => clearTimeout(redirectTimeout);
        }
    }, [showDialog, navigate]);

    // Manejo de la inactividad y recalcular tiempo
    useEffect(() => {
        const handleMouseMove = () => {
            setLastActiveTime(Date.now());
        };

        const checkInactivity = () => {
            if (Date.now() - lastActiveTime > 60000 && typeof onRecalculateTime === "function") {
                onRecalculateTime();
            }
        };

        window.addEventListener("mousemove", handleMouseMove);
        const inactivityInterval = setInterval(checkInactivity, 1000);

        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
            clearInterval(inactivityInterval);
        };
    }, [lastActiveTime, onRecalculateTime]);

    // Formatear tiempo como mm:ss
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes < 10 ? "0" : ""}${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
    };

    return (
        <Stack direction="column" justifyContent="center" alignItems="flex-end">
            <Stack justifyContent="center" alignItems="center">
                <Typography
                    variant="h5"
                    style={{
                        color: "white",
                        textAlign: isMobile ? "center" : "right",
                        fontFamily: "FuturaP-ExtraBold",
                        textTransform: "uppercase",
                        fontSize: isMobile ? "8px" : "16px",
                    }}
                >
                    Tiempo restante
                </Typography>
                <Typography
                    variant="h5"
                    style={{
                        color: time <= 5 * 60 ? "red" : "white",
                        textAlign: isMobile ? "center" : "right",
                        fontFamily: "FuturaP-ExtraBold",
                        textTransform: "uppercase",
                        fontSize: isMobile ? "15px" : "36px",
                    }}
                >
                    {formatTime(time)}
                </Typography>
                <Typography
                    variant="h6"
                    style={{
                        color: "white",
                        textAlign: isMobile ? "center" : "right",
                        fontFamily: "FuturaP-ExtraBold",
                        textTransform: "uppercase",
                        fontSize: isMobile ? "10px" : "20px",
                    }}
                >
                    {/* Hora de inicio: {convert24To12HourFormat(startTime)} */}
                </Typography>
            </Stack>

            {showDialog && <MessageDialog title="¡Lo sentimos, el tiempo ha terminado!" description="Serás redirigido a la cartelera en unos segundos." icon="/content/img/SC/alertIcon.png" width="50%" alert={true} />}
        </Stack>
    );
};

export default CountdownTimer;
