import { isLoadingPgeHome, setslidesHome, setslidesBillboard, setslidesPreSale, setsallMoviesBillboard, dataArrayMovies, setarrayInfoMovie, setisLoadingInfo, setarrayTandaMovie, setarrayTandaMovieEmpty, seterrorMessage, isPageError500, isPageError210 } from "./homeSlice";
import { errorMessages, apiUrlImg } from "../../api";
import { AvailabledaysAsync, getConsolidatedInfoAsync, getTandasBdAsync } from "./Api_Movies_Provider";
import { isFulfilled } from "@reduxjs/toolkit";

export const fetchSlides = () => {
    /* Esta función es un thunk que se encarga de hacer la petición al servidor para obtener la lista de Peliculas */
    return async (dispatch, getState) => {
        /* Acciones a ejecutar dependiendo del código de estado de la respuesta */
        const statusActions = {
            500: () => dispatch(seterrorMessage({ errorMessage: errorMessages[0] })),
            210: () => dispatch(seterrorMessage({ errorMessage: errorMessages[1] })),
        };

        let getStatus = 1;
        // Obtiene la información consolidada de manera asíncrona
        const result = await getConsolidatedInfoAsync({ getStatus });

        /* proceso de validacion de la respuesta de la API y errores */
        if (result.ok === false) {
            dispatch(isPageError500(false));
            return statusActions[500]();
        }

        // Se obtiene el código de estado y los datos de la respuesta
        const { statusCode, data } = result.data;

        if (result.data.error) {
            dispatch(isPageError210(false));
            dispatch(seterrorMessage({ errorSistem: result.data.error }));
            return;
        }
        if (statusActions[statusCode]) {
            dispatch(isPageError210(false));
            statusActions[statusCode]();
            return;
        }

        // Estos son los arreglos que se llenan en el homeSlice para mostrar en las películas en el home
        const slidesHome = [];
        const slidesBillboard = [];
        const slidesPreSale = [];
        const allMoviesBillboard = [];

        /**
         * Función que se encarga de recorrer el arreglo de películas y llenar los arreglos de slides
         * @param {Array} movies - Arreglo de películas a procesar
         * @param {number} bannerId - ID de la sección del banner
         * @param {number} principalID - ID de la sección principal
         * @param {number} imageId - ID de la sección de la imagen
         * @param {string} typeImage - Tipo de imagen a utilizar para la URL
         * @returns {Array} Arreglo de objetos procesados con la información de las películas
         */
        function processMovies(movies, bannerId, principalID, imageId, typeImage) {
            return movies.map((movie) => {
                let text3 = "";
                let ImageName = "";

                // Recorre los contenidos de cada película para extraer la descripción y la imagen
                movie.contents.forEach((content) => {
                    if ([bannerId, principalID].includes(content.id_section_tb_fk_content_tb)) {
                        text3 = content.h1_description_content_tb;
                    }
                    if (content.id_section_tb_fk_content_tb === imageId) {
                        ImageName = apiUrlImg + typeImage + content.image_path_content_tb;
                    }
                });

                // Obtiene el estado de preventa de la película
                const presaleStatus = new Set(movie.types.flatMap((type) => type.movies.map((m) => m.is_presale_movie_bp)));
                let TypeMovie = "";

                // Determina el tipo de película según el estado de preventa
                if (presaleStatus.size === 1) {
                    const [status] = presaleStatus;
                    TypeMovie = ["", "Preventa", "Estreno", "Próximamente"][status] || "";
                }

                // Retorna un objeto con la información procesada de la película
                return {
                    idMasterMovie: movie.id_master_movie_tb,
                    backgroundImage: ImageName,
                    text1: TypeMovie,
                    text2: movie.name_master_movie_tb,
                    text3: text3,
                    buttonText: "COMPRÁ TUS ENTRADAS",
                };
            });
        }

        // Obtiene los datos de la API
        const dataApi = result.data.data;
        // Procesa los datos de las películas para obtener los banners
        const dataReturnbanners = processMovies(dataApi, 1, 3, 1, "/banners/");

        // Despacha la acción para actualizar los slides en el home
        dispatch(setslidesHome([...slidesHome, ...dataReturnbanners]));

        /**
         * Función que procesa películas para cartelera y preventa
         * @param {Array} movies - Arreglo de películas a procesar
         * @param {number} singId1 - ID de preventa
         * @param {number} singId2 - ID de cartelera
         * @param {string} text1 - Texto de preventa
         * @param {string} text2 - Texto de cartelera
         * @param {number} imageId - ID de la sección de la imagen
         * @param {string} typeImage - Tipo de imagen a utilizar para la URL
         * @returns {Array} Arreglo de objetos procesados con la información de las películas
         */
        function processInBillboard(movies, singId1, singId2, text1, text2, imageId, typeImage) {
            return movies
                .map((movie) => {
                    let ImageName = "";
                    let TypeMovie = "";

                    // Recorre los contenidos de cada película para extraer la imagen
                    movie.contents.forEach((content) => {
                        if (content.id_section_tb_fk_content_tb === imageId) {
                            ImageName = apiUrlImg + typeImage + content.image_path_content_tb;
                        }
                    });

                    // Validamos para qué tipo de público va dirigida la película
                    const presaleTp = new Set(movie.types.flatMap((type) => type.movies.map((m) => m.classification_info_movie_bp)));
                    const singleValueTP = [...presaleTp][0];

                    // Recorremos todos los campos para extraer la duración de la película
                    const presaleHours = new Set(movie.types.flatMap((type) => type.movies.map((m) => m.duration_info_movie_bp)));
                    const singleValueHours = [...presaleHours][0];

                    // Convertir minutos a horas y minutos
                    const hours = Math.floor(singleValueHours / 60);
                    const minutes = singleValueHours % 60;

                    // Formatear el resultado como "X HR Y MIN"
                    const formattedTime = `${hours} HR ${minutes} MIN`;

                    // Obtiene el estado de preventa de la película
                    const presaleStatus = new Set(movie.types.flatMap((type) => type.movies.map((m) => m.is_presale_movie_bp)));
                    // Extrae el valor único del Set
                    const singleValue = [...presaleStatus][0];
                    if (singleValue === singId1 || singleValue === singId2) {
                        singleValue === singId1 ? (TypeMovie = text1) : (TypeMovie = text2);
                        // Retorna un objeto con la información procesada de la película
                        return {
                            idMasterMovie: movie.id_master_movie_tb,
                            image: ImageName,
                            title: movie.name_master_movie_tb,
                            subtitle: formattedTime + " | " + singleValueTP,
                            buttonText: "COMPRÁ TUS ENTRADAS",
                            type: TypeMovie,
                        };
                    }

                    if (singId1 === 100) {
                        singleValue === 1 ? (TypeMovie = "preventa") : singleValue === 3 ? (TypeMovie = "próximamente") : singleValue === 2 ? (TypeMovie = "estreno") : singleValue === 0 ? (TypeMovie = "cartelera") : (TypeMovie = "cartelera");

                        // Retorna un objeto con la información procesada de la película
                        return {
                            idMasterMovie: movie.id_master_movie_tb,
                            image: ImageName,
                            title: movie.name_master_movie_tb,
                            subtitle: formattedTime + " | " + singleValueTP,
                            buttonText: "COMPRÁ TUS ENTRADAS",
                            type: TypeMovie,
                            singId1: singleValue,
                        };
                    }
                })
                .filter((item) => item !== undefined);
        }

        // Procesa los datos de las películas para obtener los banners
        const dataReturnBillboard = processInBillboard(dataApi, 2, 0, "estreno", "cartelera", 2, "/cartelera/");

        // Despacha la acción con el array filtrado
        dispatch(setslidesBillboard([...slidesBillboard, ...dataReturnBillboard]));

        const dataReturnslidesPreSale = processInBillboard(dataApi, 1, 3, "preventa", "próximamente", 2, "/cartelera/");

        // Despacha la acción con el array filtrado
        dispatch(setslidesPreSale([...slidesPreSale, ...dataReturnslidesPreSale]));

        const dataReturnallMoviesBillboard = processInBillboard(dataApi, 100, 100, "preventa", "próximamente", 2, "/cartelera/");

        // Define el orden deseado
        const customOrder = { 0: 0, 2: 1, 1: 2, 3: 3 };

        // Función de comparación que utiliza el orden personalizado
        const customSort = (a, b) => customOrder[a.singId1] - customOrder[b.singId1];

        // Ordenar el array utilizando la función de comparación personalizada
        const sortedMovies = dataReturnallMoviesBillboard.sort(customSort);

        // Despacha la acción con el array filtrado
        dispatch(setsallMoviesBillboard([...allMoviesBillboard, ...sortedMovies]));

        dispatch(dataArrayMovies(result.data.data));

        // Indica que la página de inicio ha terminado de cargar
        dispatch(isLoadingPgeHome(true));
    };
};

/* funcion que se encarga de obtener la informacion de la pelicula seleccionada
    Psamos como parametro la fecha de la pelicula seleccionada
    @param {getFech} - Fecha de la pelicula seleccionada
*/
export const updatearrayInfoMovie = () => {
    /* Esta función es un thunk que se encarga de hacer la petición al servidor para obtener la información detallada de una película seleccionada */
    return async (dispatch, getState) => {
        /* Acciones a ejecutar dependiendo del código de estado de la respuesta */
        const statusActions = {
            500: () => dispatch(seterrorMessage({ errorMessage: errorMessages[0] })),
            210: () => dispatch(seterrorMessage({ errorMessage: errorMessages[1] })),
        };

        // Indica que la información de la película está siendo cargada
        dispatch(setisLoadingInfo(false));

        const { dataArrayMovies, activeMovieInfo } = getState().home;

        const arrayInfo = [];

        try {
            const activeMovieId = parseInt(activeMovieInfo, 10);
            const selectedMovie = dataArrayMovies.find((movie) => movie.id_master_movie_tb === activeMovieId);

            // Extraer horas de preventa
            const presaleHours = new Set(selectedMovie.types.flatMap((type) => type.movies.map((m) => m.duration_info_movie_bp)));
            const singleValueHours = [...presaleHours][0];
            const hours = Math.floor(singleValueHours / 60);
            const minutes = singleValueHours % 60;
            const formattedTime = `${hours} HR ${minutes} MIN`;

            // Extraer imágenes y descripción de la película
            let img1, img2, dec;
            selectedMovie.contents.forEach((content) => {
                if (content.id_section_tb_fk_content_tb === 2) {
                    img1 = apiUrlImg + "/cartelera/" + content.image_path_content_tb;
                }
                if (content.id_section_tb_fk_content_tb === 3) {
                    img2 = apiUrlImg + "/principal/" + content.image_path_content_tb;
                }
                if (content.id_section_tb_fk_content_tb === 3) {
                    dec = content.h1_description_content_tb;
                }
            });

            // Validar clasificación de la película
            const presaleTp = new Set(selectedMovie.types.flatMap((type) => type.movies.map((m) => m.classification_info_movie_bp)));
            const singleValueTP = [...presaleTp][0];

            // Extraer género de la película
            const genderTP = new Set(selectedMovie.types.flatMap((type) => type.movies.map((m) => m.genre_info_movie_bp)));
            const genderTPin = [...genderTP][0];

            // Formatear URL de YouTube
            async function formatYouTubeUrl(url) {
                if (!url) return "";
                if (!url.includes("www.")) {
                    url = url.replace("https://", "https://www.");
                }
                const videoId = url.split("v=")[1] || url.split("/").pop();
                if (!videoId) return "";
                return `https://www.youtube.com/embed/${videoId}`;
            }

            const urlMovie = new Set(selectedMovie.types.flatMap((type) => type.movies.map((m) => m.url_trailer_info_movie_bp)));
            const urlMovieTp = [...urlMovie][0];
            const formattedUrl = await formatYouTubeUrl(urlMovieTp);

            // Construir objeto de datos recuperados
            const recoverDta = {
                id: selectedMovie.id_master_movie_tb,
                title: selectedMovie.name_master_movie_tb,
                duration: formattedTime,
                image: img1,
                description: selectedMovie.contents[0].h1_description_content_tb,
                trailer: formattedUrl,
                imgheader: img2,
                censura: singleValueTP,
                genre: genderTPin,
            };

            arrayInfo.push(recoverDta);

            // Actualizar estado con los datos recuperados
            dispatch(setarrayInfoMovie([...arrayInfo, recoverDta]));


        } catch (error) {
            console.error(error);
        }

        // Indica que la información de la película ha terminado de cargar
        dispatch(setisLoadingInfo(true));

        // Indicar que la página de inicio está cargando
        dispatch(isLoadingPgeHome(true));

         return arrayInfo;
    };
};
//************************************ getTandasBd **********************************************/

/**
 * Función principal para obtener y procesar los datos de las tandas de películas.
 * @param {string} selectedDate - Fecha seleccionada por el usuario.
 * @param {number} valor - Valor que determina cómo se procesa la fecha seleccionada.
 * @returns {Function} Función asíncrona para ser ejecutada por Redux Thunk.
 */
export const getTandasBd = (selectedDate, valor) => {

    return async (dispatch, getState) => {

        if (selectedDate.length === 0) {
            dispatch(seterrorMessage("No hay tandas disponibles para el día seleccionado"));
            return;
        }
        // Limpia datos previos y mensajes de error
        dispatch(setarrayTandaMovieEmpty(false));
        dispatch(seterrorMessage(""));

        /**
         * Formatea una fecha al formato YYYY-MM-DD en la zona horaria de Costa Rica.
         * @param {Date} date - Objeto Date a formatear.
         * @returns {string} Fecha formateada.
         */
        const formatDate = (date) => date.toLocaleDateString("es-CR", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            timeZone: "America/Costa_Rica",
        }).split("/").reverse().join("-");

        // Obtiene la fecha actual en Costa Rica
        const costaRicaDate = new Date();

        // Determina la fecha de la tanda basada en el valor proporcionado
        const fechaTanda = valor === 1 ? selectedDate : selectedDate[0].value;
        
        // Comprueba si la fecha de la tanda es hoy
        const isToday = fechaTanda === formatDate(costaRicaDate);
        
        // Establece la hora de envío: hora actual si es hoy, o 08:00:00 si es otro día
        const horaEnvio = isToday ? costaRicaDate.toLocaleTimeString("es-CR", { hour12: false }) : "08:00:00";

        // Obtiene el ID de la película activa del estado de Redux
        const { activeMovieInfo } = getState().home;
        const activeMovieId = parseInt(activeMovieInfo, 10);

        try {
            // Realiza la llamada a la API para obtener los datos de las tandas
            const result = await getTandasBdAsync({ fechaTanda, horaEnvio, activeMovieId });
            const { statusCode, data } = result.data;

            // Si no hay tandas disponibles, muestra un mensaje de error
            if (statusCode === 210) {
                dispatch(seterrorMessage(`No hay tandas disponibles para el : ${fechaTanda}`));
                return;
            }

            // Procesa los datos de las películas
            const movies = processMovieData(data);
            
            // Actualiza el estado de Redux con los datos procesados
            dispatch(setarrayTandaMovie(Object.values(movies)));
        } catch (error) {
            // Maneja cualquier error que ocurra durante el proceso
            console.error(`[${new Date().toISOString()}] Error en getTandasBd:`, error);
            dispatch(seterrorMessage("Error al obtener las tandas"));
        }
    };
};

/**
 * Procesa los datos crudos de las películas y los organiza en una estructura más manejable.
 * @param {Array} data - Array de datos crudos de películas y tandas.
 * @returns {Object} Objeto con las películas organizadas y sus horarios.
 */
const processMovieData = (data) => {
    const movies = {};

    data.forEach((item) => {
        // Prepara el nombre completo de la película incluyendo la tecnología
        const technologyName = item.technology_name_shifts_bp.trim();
        const fullName = `${item.voiceover_movie_bp.trim()} (${technologyName})`;

        // Si es la primera vez que se encuentra esta película, inicializa su objeto
        if (!movies[fullName]) {
            movies[fullName] = {
                id: item.id_movie_bp,
                name: fullName,
                horarios: [],
            };
        }

        // Agrega el horario a la película correspondiente
        movies[fullName].horarios.push({
            id: item.id_shifts_bp,
            idMaster: item.id_master_movie_tb,
            hora: convertTimeTo12Hour(item.start_time_shifts_bp),
            id_master_movie_tb: item.id_master_movie_tb,
            id_movie_bp: item.id_movie_bp,
            name_movie_bp: item.name_movie_bp,
            id_shifts_bp: item.id_shifts_bp,
            id_movie_shifts_bp: item.id_movie_shifts_bp,
            comp_id_shifts_bp: item.comp_id_shifts_bp,
            str_id_shifts_bp: item.str_id_shifts_bp,
            scheme_id_shifts_bp: item.scheme_id_shifts_bp,
            scheme_Detail_Id_shifts_bp: item.scheme_Detail_Id_shifts_bp,
            room_id_shifts_bp: item.room_id_shifts_bp,
            movie_id_shifts_bp: item.movie_id_shifts_bp,
            price_list_id_shifts_bp: item.price_list_id_shifts_bp,
            date_time_shifts_bp: item.date_time_shifts_bp,
            start_time_shifts_bp: item.start_time_shifts_bp,
            end_time_shifts_bp: item.end_time_shifts_bp,
            technology_name_shifts_bp: item.technology_name_shifts_bp,
            room_name_shifts_bp: item.room_name_shifts_bp,
            status_shifts_bp: item.status_shifts_bp,
            pricelist: item.pricelist,
        });
    });

    return movies;
};

/**
 * Convierte una hora en formato de 24 horas a formato de 12 horas.
 * @param {string} time - Hora en formato "HH:MM".
 * @returns {string} Hora en formato "HH:MM AM/PM".
 */
const convertTimeTo12Hour = (time) => {
    const [hour, minute] = time.split(":");
    const hourInt = parseInt(hour, 10);
    const period = hourInt >= 12 ? "PM" : "AM";
    const hour12 = hourInt % 12 || 12;
    return `${hour12}:${minute} ${period}`;
};


//************************************** Availabledays ******************************************* */

export const Availabledays = () => {
    return async (dispatch, getState) => {
        // Reset loading state and clear any previous error messages
        dispatch(setisLoadingInfo(false));
        dispatch(setarrayTandaMovieEmpty(false));
        dispatch(seterrorMessage(""));

        const { dataArrayMovies, activeMovieInfo } = getState().home;

        try {
            // Find the selected movie and extract its types and movie IDs
            const activeMovieId = parseInt(activeMovieInfo, 10);
            const selectedMovie = dataArrayMovies.find((movie) => movie.id_master_movie_tb === activeMovieId);
            const types = selectedMovie.types;
            const movieIds = types.flatMap((type) => type.movies.map((m) => m.id_movie_bp));
            const moviesId = movieIds.join(",");

            // Fetch available days for the selected movies
            const result = await AvailabledaysAsync({ moviesId });

            /**
             * Formats a date to YYYY-MM-DD in Costa Rica timezone.
             * @param {Date} date - Date object to format.
             * @returns {string} Formatted date string.
             */
            const formatDate = (date) =>
                date
                    .toLocaleDateString("es-CR", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        timeZone: "America/Costa_Rica",
                    })
                    .split("/")
                    .reverse()
                    .join("-");

            // Get the current date in Costa Rica
            const costaRicaDate = new Date();
            const currentFormattedDate = formatDate(costaRicaDate);

            // Arrays for day and month names
            const daysOfWeek = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
            const monthsShort = ["Ene", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

            const formattedDays = [];

            result.data.data.forEach((item) => {
                // Create date object for the current item in Costa Rica timezone
                const itemDate = new Date(`${item.date_time_shifts_bp}T00:00:00-06:00`);

                // Compare the item's date with the current date
                if (item.date_time_shifts_bp >= currentFormattedDate) {
                    // Check if the date is today
                    const isToday = item.date_time_shifts_bp === currentFormattedDate;

                    // Format the day label
                    const dayLabel = isToday ? `Hoy (${daysOfWeek[itemDate.getDay()]}) ${itemDate.getDate()} ${monthsShort[itemDate.getMonth()]}` : `${daysOfWeek[itemDate.getDay()]}: ${itemDate.getDate()} ${monthsShort[itemDate.getMonth()]}`;

                    // Add the formatted day to the array
                    formattedDays.push({
                        label: dayLabel,
                        value: item.date_time_shifts_bp,
                    });
                }
            });

            // Sort formattedDays by date
            formattedDays.sort((a, b) => new Date(a.value) - new Date(b.value));

            return formattedDays;
        } catch (error) {
            console.error("Error fetching available days:", error);
            return [];
        }
    };
};

