import { commonRequestData, fetchData } from "../../api";

export const getConsolidatedInfoAsync = async ({ getStatus }) => {
    // cuerpo de la solicitud que se enviará al servidor.
    const requestData = {
        ...commonRequestData,
        getStatus,
    };
    // Realiza una solicitud utilizando fetchData y retorna la respuesta
    return await fetchData("cineverse/getAllMoviesDataBaseCineverse", requestData);
};


export const getTandasBdAsync = async ({ fechaTanda, horaEnvio, activeMovieId }) => {
    // cuerpo de la solicitud que se enviará al servidor.
    const requestData = {
        ...commonRequestData,
        fechaTanda,
        horaEnvio,
        activeMovieId,
    };
    // Realiza una solicitud utilizando fetchData y retorna la respuesta
    return await fetchData("cineverse/getShiftsByMasterMovieIdAndDate", requestData);
};


export const insertSessionIdAsync = async ({ id_sessions_cv, date_sessions_cv, time_sessions_cv, flag_sessions_cv, movie_date_sessions_cv, seats_sessions_cv, room_sessions_cv, esquemaid_sessions_cv, esquema_detalleid_sessions_cv }) => {
    // cuerpo de la solicitud que se enviará al servidor.
    const requestData = {
        ...commonRequestData,
        id_sessions_cv,
        date_sessions_cv,
        time_sessions_cv,
        flag_sessions_cv,
        seats_sessions_cv,
        movie_date_sessions_cv,
        room_sessions_cv,
        esquemaid_sessions_cv,
        esquema_detalleid_sessions_cv,
    };
    // Realiza una solicitud utilizando fetchData y retorna la respuesta
    return await fetchData("cineverse/insertSession", requestData);
};

export const FetchPriceListAsync = async ({ pelicula_id, fechaTanda, horaEnvio }) => {
    // cuerpo de la solicitud que se enviará al servidor.
    const requestData = {
        ...commonRequestData,
        pelicula_id,
        fechaTanda,
        horaEnvio,
    };
    // Realiza una solicitud utilizando fetchData y retorna la respuesta
    return await fetchData("cineverse/getShiftPrices", requestData);
};


export const addSeatingFunctionAsync = async ({ sala_id, esquema_id, esquema_detalle_id, fecha, idSesion }) => {
    // cuerpo de la solicitud que se enviará al servidor.
    const requestData = {
        ...commonRequestData,
        sala_id,
        esquema_id,
        esquema_detalle_id,
        fecha,
        idSesion,
    };
    // Realiza una solicitud utilizando fetchData y retorna la respuesta
    return await fetchData("cineverse/getSeating", requestData);
};



export const selectSeatAsync = async ({ sala_id, butaca_id, esquema_id, esquema_detalle_id, fecha, pelicula_id, pelicula_nombre, idSesion}) => {
    // cuerpo de la solicitud que se enviará al servidor.
    const requestData = {
        ...commonRequestData,
        sala_id,
        butaca_id,
        esquema_id,
        esquema_detalle_id,
        fecha,
        pelicula_id,
        pelicula_nombre,
        idSesion,
    };
    // Realiza una solicitud utilizando fetchData y retorna la respuesta
    return await fetchData("cineverse/selectSeat", requestData);
};


export const deselectSeatAsync = async ({ sala_id, butaca_id, esquema_id, esquema_detalle_id, fecha }) => {
    // cuerpo de la solicitud que se enviará al servidor.
    const requestData = {
        ...commonRequestData,
        sala_id,
        butaca_id,
        esquema_id,
        esquema_detalle_id,
        fecha,
    };
    // Realiza una solicitud utilizando fetchData y retorna la respuesta
    return await fetchData("cineverse/deselectSeat", requestData);
};


export const sessionActiveAsync = async ({ esquema_id, esquema_detalle_id, sessionId, fecha_pelicula }) => {
    // cuerpo de la solicitud que se enviará al servidor.
    const requestData = {
        ...commonRequestData,
        esquema_id,
        esquema_detalle_id,
        sessionId,
        fecha_pelicula,
    };
    // Realiza una solicitud utilizando fetchData y retorna la respuesta
    return await fetchData("cineverse/getSessionSeat", requestData);
};

export const trasnsactionPaymentAsync = async ({ commonParams, cat_id_pricelist, list_Id_pricelist, createTemp, applyTemp, peopleCantidad }) => {
    // cuerpo de la solicitud que se enviará al servidor.
    const requestData = {
        ...commonRequestData,
        commonParams,
        cat_id_pricelist,
        list_Id_pricelist,
        createTemp,
        applyTemp,
        peopleCantidad
    };
    // Realiza una solicitud utilizando fetchData y retorna la respuesta
    return await fetchData("cineverse/create_Invoice", requestData);
};

export const insertWebClientAsync = async ({ invoiceId, nombreCliente, cedulaCliente, telefonoCliente, mailCliente, idSesion, tipoDocumento }) => {
    // cuerpo de la solicitud que se enviará al servidor.
    const requestData = {
        ...commonRequestData,
        invoiceId,
        nombreCliente,
        cedulaCliente,
        telefonoCliente,
        mailCliente,
        idSesion,
        tipoDocumento,
    };
    // Realiza una solicitud utilizando fetchData y retorna la respuesta
    return await fetchData("cineverse/insertWebClient", requestData);
};


export const processArticulosPaymentAsync = async ({ idSesion, product_id, product_name, product_price1, cantidad }) => {
    // cuerpo de la solicitud que se enviará al servidor.
    const requestData = {
        ...commonRequestData,
        idSesion,
        product_id,
        product_name,
        product_price1,
        cantidad,
    };
    // Realiza una solicitud utilizando fetchData y retorna la respuesta
    return await fetchData("cineverse/save_PurchaseWeb", requestData);
};



export const insertPaymentAttemptAsync = async ({  idSession, idInvoice, clavefe, details_json, total, status, transBank,type}) => {
    // cuerpo de la solicitud que se enviará al servidor.
    const requestData = {
        ...commonRequestData,
        idSession,
        idInvoice,
        clavefe,
        details_json,
        total,
        status,
        transBank,
        type,
    };
    // Realiza una solicitud utilizando fetchData y retorna la respuesta
    return await fetchData("cineverse/insertPaymentAttempt", requestData);
};

export const updateSessionStatusAsync = async ({ status,sessionId }) => {
    // cuerpo de la solicitud que se enviará al servidor.
    const requestData = {
        ...commonRequestData,
        status,
        sessionId,
    };
    // Realiza una solicitud utilizando fetchData y retorna la respuesta
    return await fetchData("cineverse/updateSessionStatus", requestData);
};



export const getArituculeAsync = async () => {
    // cuerpo de la solicitud que se enviará al servidor.
    const requestData = {
        ...commonRequestData,
    };
    // Realiza una solicitud utilizando fetchData y retorna la respuesta
    return await fetchData("cineverse/getAritucule", requestData);
};


export const InsertArituculeAsync = async ({ category, productData }) => {
    // cuerpo de la solicitud que se enviará al servidor.
    const requestData = {
        ...commonRequestData,
        category,
        productData,
    };
    // Realiza una solicitud utilizando fetchData y retorna la respuesta
    return await fetchData("cineverse/Articule", requestData);
};


export const insertFatherLine = async ({  idSesion, product_id, product_name, product_price1, cantidad, product_combo, presentation_type, line,
 }) => {
    // cuerpo de la solicitud que se enviará al servidor.
    const requestData = {
        ...commonRequestData,
        idSesion,
        product_id,
        product_name,
        product_price1,
        cantidad,
        product_combo,
        presentation_type,
        line,
    };
    // Realiza una solicitud utilizando fetchData y retorna la respuesta
    return await fetchData("cineverse/save_Compra_Web_Combo", requestData);
};



export const insertCHiledLine = async ({ idSesion, line, id_padre, name_combo, product_detail, nombre_escogencia, grupo }) => {
    // cuerpo de la solicitud que se enviará al servidor.
    const requestData = {
        ...commonRequestData,
        idSesion,
        line,
        id_padre,
        name_combo,
        product_detail,
        nombre_escogencia,
        grupo,
    };
    // Realiza una solicitud utilizando fetchData y retorna la respuesta
    return await fetchData("cineverse/save_Compra_Web_Combo_Line", requestData);
};



export const create_Invoice_tem_Cineverse = async ({ idSesion, company, branch, cant, nombreCliente, cedulaCliente, crear_temporal, aplicar_temporal }) => {
    // cuerpo de la solicitud que se enviará al servidor.
    const requestData = {
        ...commonRequestData,
        idSesion,
        company,
        branch,
        cant,
        nombreCliente,
        cedulaCliente,
        crear_temporal,
        aplicar_temporal,
    };
    // Realiza una solicitud utilizando fetchData y retorna la respuesta
    return await fetchData("cineverse/create_Invoice_Cineverse", requestData);
};





export const delete_Temporal_InvoiceAsync = async ({ idSesion }) => {
    // cuerpo de la solicitud que se enviará al servidor.
    const requestData = {
        ...commonRequestData,
        idSesion
    };
    // Realiza una solicitud utilizando fetchData y retorna la respuesta
    return await fetchData("cineverse/delete_Temporal_Invoice", requestData);
};

export const delete_Temporal_transactionAsync = async ({ idSesion }) => {
    // cuerpo de la solicitud que se enviará al servidor.
    const requestData = {
        ...commonRequestData,
        idSesion
    };
    // Realiza una solicitud utilizando fetchData y retorna la respuesta
    return await fetchData("cineverse/delete_Temporal_transactionAsync", requestData);
};


export const updateClientTransactionAsync = async ({ idSession, idInvoice, clavefe, status, transBank, DataPymentPneding }) => {
    // cuerpo de la solicitud que se enviará al servidor.
    const requestData = {
        ...commonRequestData,
        idSession,
        idInvoice,
        clavefe,
        status,
        transBank,
        DataPymentPneding,
    };
    // Realiza una solicitud utilizando fetchData y retorna la respuesta
    return await fetchData("cineverse/updateClientTransactionAsync", requestData);
};


export const getInvoiceIdAsync = async ({ idInvoice }) => {
    // cuerpo de la solicitud que se enviará al servidor.
    const requestData = {
        ...commonRequestData,
        idInvoice,
    };
    // Realiza una solicitud utilizando fetchData y retorna la respuesta
    return await fetchData("cineverse/getInvoiceIdAsync", requestData);
};



export const geTemporaryPaymentFunctionAsync = async ({ movieValue }) => {
    // cuerpo de la solicitud que se enviará al servidor.
    const requestData = {
        ...commonRequestData,
        movieValue,
    };
    // Realiza una solicitud utilizando fetchData y retorna la respuesta
    return await fetchData("cineverse/geTemporaryPaymentFunctionAsync", requestData);
};

export const geTemporaryPaymentInsertFunctionAsync = async ({ dataHome, dataChekout, dataAuth, banckInfo, setepPyme, movieValue, fecha, hora }) => {
    // cuerpo de la solicitud que se enviará al servidor.
    const requestData = {
        ...commonRequestData,
        dataHome,
        dataChekout,
        dataAuth,
        banckInfo,
        setepPyme,
        movieValue,
        fecha,
        hora,
    };
    // Realiza una solicitud utilizando fetchData y retorna la respuesta
    return await fetchData("cineverse/geTemporaryPaymentInsertFunctionAsync", requestData);
};

export const geTemporaryPaymentUpdateFunctionAsync = async ({ dataHome, dataChekout, dataAuth, banckInfo, setepPyme, movieValue }) => {
    // cuerpo de la solicitud que se enviará al servidor.
    const requestData = {
        ...commonRequestData,
        dataHome,
        dataChekout,
        dataAuth,
        banckInfo,
        setepPyme,
        movieValue
    };
    // Realiza una solicitud utilizando fetchData y retorna la respuesta
    return await fetchData("cineverse/geTemporaryPaymentUpdateFunctionAsync", requestData);
};