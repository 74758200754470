import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import "../styles/index.css";
import { NavLink } from "react-router-dom";

function Footer() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery("(max-width:1024px)"); // Verifica si la pantalla es móvil

    return (
        <>
            {isMobile ? (
                <Stack direction="row" justifyContent="space-evenly" alignItems="flex-start" style={{ width: "100%", height: "auto", backgroundColor: "#000", paddingLeft: "20px", paddingTop: "5px" }}>
                    <Stack direction="row" justifyContent="space-evenly" alignItems="flex-start" spacing={3}>
                        <Stack>
                            <Stack spacing={1}>
                            <NavLink to="/cartelera" className={"textBoldFooter"}>
                                CARTELERA
                            </NavLink>
                            <NavLink to="/cartelera" className={"textLightFooter"}>
                                PREVENTA
                            </NavLink>
                            <NavLink to="/cartelera" className={"textLightFooter"}>
                                PRÓXIMAMENTE
                            </NavLink>
                            <NavLink to="/confiteria" className={"textBoldFooter"}>
                            CONFITERÍA
                            </NavLink>
                            
                            </Stack>
                            <Stack spacing={1} style={{ marginTop: "20px" }}>
                            <NavLink to="/nosotros" className={"textBoldFooter"}>
                                OTROS
                            </NavLink>
                            <NavLink to="/nosotros" className={"textLightFooter"}>
                                SOBRE NOSOTROS
                            </NavLink>
                            <NavLink to="/legales" className={"textLightFooter"}>
                            TÉRMINOS Y CONDICIONES
                            </NavLink>
                            <NavLink to="/nosotros" className={"textLightFooter"}>
                            CONTACTO
                            </NavLink>
                                
                            </Stack>
                            <Stack spacing={1}>
                                <Typography className="textBoldFooterresponsive" style={{ marginTop: "20px" }}>
                                    City Place Santa Ana, San José
                                </Typography>
                                <Typography className="textLightFooterresponsive">Correo: info@studiocinemascr.com</Typography>
                                <Typography className="textLightFooterresponsive">Tel: 4035-5000 / 4035 3105</Typography>
                                <Typography className="textLightFooterresponsive">Todos los derechos reservados © 2024 
                            </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack justifyContent="center" alignItems="center" spacing={7} style={{ marginLeft: "-9%" }}>
                        {/* <img src="/content/img/SC/newLogoSC.png" style={{ width: "100px" }} alt="Logo" /> */}
                        <img src="/content/img/SC/halloween/logoHalloween.svg" style={{ width: "100px" }} alt="Logo" /> 
                        <Stack justifyContent="center" alignItems="center">
                            <a href="#" className="textBoldFooter">
                                SÍGUENOS
                            </a>
                            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                                <Stack spacing={1}>
                                    <a href="https://www.facebook.com/studiocinemascr/" className="textLightFooter">
                                        <img src="/content/img/SC/facebook.png" style={{ width: "20px" }} alt="Facebook" />
                                    </a>
                                    <a href="https://www.instagram.com/_studiocinemascr_/" className="textLightFooter">
                                        <img src="/content/img/SC/instagram.png" style={{ width: "20px" }} alt="Instagram" />
                                    </a>
                                </Stack>
                                <Stack spacing={1}>
                                    <a href="https://www.tiktok.com/@studiocinemascr" className="textLightFooter">
                                        <img src="/content/img/SC/tiktok.png" style={{ width: "20px" }} alt="TikTok" />
                                    </a>
                                    <a href="https://www.google.com/maps/place//data=!4m3!3m2!1s0x8fa0feabded40273:0x504ef7c4472421ea!12e1?source=g.page.m.ia._&laa=nmx-review-solicitation-ia2" className="textLightFooter">
                                        <img src="/content/img/SC/google.svg" style={{ width: "20px" }} alt="Google" />
                                    </a>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            ) : (
                <Stack style={{ width: "100%", height: "224px", backgroundColor: "#000", paddingLeft: "10px", paddingTop: "10px", marginTop:"-1%" }}>
                    <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={5}>
                        <Stack spacing={1}>
                            {/* <img src="/content/img/SC/newLogoSC.png" style={{ width: "80px" }} alt="Logo" /> */}
                            <img src="/content/img/SC/halloween/logoHalloween.svg" style={{ width: "100px" }} alt="Logo" /> 
                            <Typography className="textBoldFooterFijo U" >City Place Santa Ana, San José</Typography>
                            <Typography className="textLightFooterFijo U" >Correo: info@studiocinemascr.com</Typography>
                            <Typography className="textLightFooterFijo U" >Tel: 4035-5000 / 4035 3105</Typography>
                            <Typography className="textLightFooterFijo" style={{textTransform:"none !important"}}>Todos los derechos reservados © 2024 
                            </Typography>
                        </Stack>
                        <Stack spacing={1}>
                            <NavLink to="/cartelera" className={"textBoldFooter"}>
                                CARTELERA
                            </NavLink>
                            <NavLink to="/cartelera" className={"textLightFooter"}>
                                PREVENTA
                            </NavLink>
                            <NavLink to="/cartelera" className={"textLightFooter"}>
                                PRÓXIMAMENTE
                            </NavLink>
                            ;
                            <NavLink to="/confiteria" className={"textBoldFooter"}>
                                CONFITERÍA
                            </NavLink>
                        </Stack>
                        <Stack spacing={1}>
                            <NavLink to="/nosotros" className={"textBoldFooter"}>
                                OTROS
                            </NavLink>
                            <NavLink to="/nosotros" className={"textLightFooter"}>
                                SOBRE NOSOTROS
                            </NavLink>
                            <NavLink to="/legales" className={"textLightFooter"}>
                                TÉRMINOS Y CONDICIONES
                            </NavLink>

                            <NavLink to="/nosotros" className={"textLightFooter"}>
                                CONTACTO
                            </NavLink>
                        </Stack>
                        <Stack justifyContent="center" alignItems="center" spacing={2}>
                            <a href="#" className="textBoldFooter">
                                SÍGUENOS
                            </a>
                            {isTablet ? (
                                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                                    <Stack spacing={1}>
                                        <a href="https://www.facebook.com/studiocinemascr/" className="textLightFooter">
                                            <img src="/content/img/SC/facebook.png" style={{ width: "20px" }} alt="Facebook" />
                                        </a>
                                        <a href="https://www.instagram.com/_studiocinemascr_/" className="textLightFooter">
                                            <img src="/content/img/SC/instagram.png" style={{ width: "20px" }} alt="Instagram" />
                                        </a>
                                    </Stack>
                                    <Stack spacing={1}>
                                        <a href="https://www.tiktok.com/@studiocinemascr" className="textLightFooter">
                                            <img src="/content/img/SC/tiktok.png" style={{ width: "20px" }} alt="TikTok" />
                                        </a>
                                        <a href="https://www.google.com/maps/place//data=!4m3!3m2!1s0x8fa0feabded40273:0x504ef7c4472421ea!12e1?source=g.page.m.ia._&laa=nmx-review-solicitation-ia2" className="textLightFooter">
                                            <img src="/content/img/SC/google.svg" style={{ width: "20px" }} alt="Google" />
                                        </a>
                                    </Stack>
                                </Stack>
                            ) : (
                                <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={2}>
                                    <a href="https://www.facebook.com/studiocinemascr/" className="textLightFooter">
                                        <img src="/content/img/SC/facebook.png" style={{ width: "20px" }} alt="Facebook" />
                                    </a>
                                    <a href="https://www.instagram.com/_studiocinemascr_/" className="textLightFooter">
                                        <img src="/content/img/SC/instagram.png" style={{ width: "20px" }} alt="Instagram" />
                                    </a>
                                    <a href="https://www.tiktok.com/@studiocinemascr" className="textLightFooter">
                                        <img src="/content/img/SC/tiktok.png" style={{ width: "20px" }} alt="TikTok" />
                                    </a>
                                    <a href="https://www.google.com/maps/place//data=!4m3!3m2!1s0x8fa0feabded40273:0x504ef7c4472421ea!12e1?source=g.page.m.ia._&laa=nmx-review-solicitation-ia2" className="textLightFooter">
                                        <img src="/content/img/SC/google.svg" style={{ width: "20px" }} alt="Google" />
                                    </a>
                                </Stack>
                            )}
                        </Stack>
                    </Stack>
                </Stack>
            )}
        </>
    );
}

export default Footer;
